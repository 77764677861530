<template>
  <div class="login-container">
    <div class="login-box">
      <div class="left">
        <img src="@/assets/images/dashboard/img/编组@2x (2).png" alt="" />
      </div>
      <div class="login-card">
        <div class="login-title">登录/注册</div>
        <div>
          <div class="form">
            <div class="form-item">
              <img
                src="../../assets/images/login/手机号@2x.png"
                style="height: 18px;
  width: 18px;"
              />
              <input type="text" v-model="phone" placeholder="请输入手机号" />
            </div>
            <div class="form-item">
              <img
                src="../../assets/images/login/验证码@2x.png"
                style="height: 17px;
  width: 15px;"
              />
              <input v-model="sms" type="text" placeholder="请输入验证码" />
              <el-button
                class="code-btn"
                type="primary"
                round
                :disabled="smsSec > 0"
                size="small"
                @click="getSmsVercode"
                >{{ btnText }}
              </el-button>
            </div>
            <div class="login-protal">
              <el-checkbox v-model="checked"> </el-checkbox>
              登录代表你已同意
              <el-button type="text" @click="platformAgree"
                >《人单合一平台服务协议》</el-button
              >
              <el-button type="text" @click="privacyagree"
                >《隐私协议》</el-button
              >
              <el-button type="text" @click="operationStandard"
                >《人单合一平台运营规范》</el-button
              >
              <el-button type="text" @click="contentManage"
                >《人单合一平台内容管理规定》</el-button
              >
            </div>
            <el-button
              class="login-btn"
              type="primary"
              :disabled="!checked"
              round
              @click="smslogin"
              >登录
            </el-button>
            <div class="login-btn-wx">
              <img
                src="../../assets/images/login/微信LOGO_轮廓@2x.png"
                @click="wxLogin"
              />
              <div>其他登录方式</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import * as API from "@/api/index";
import { mapActions, mapGetters } from "vuex";

import Footer from "../layout/Foot.vue";
export default {
  components: {
    Footer
  },
  data() {
    return {
      btnText: "获取验证码",
      phone: "",
      sms: "",
      smsSec: 0,
      timer: 0,
      checked: false
    };
  },

  computed: {
    ...mapGetters(["userInfo"]),
    redirect_route() {
      return btoa(this.$route.query.redirect_route || "/");
    }
  },
  methods: {
    ...mapActions(["loginBySms"]),
    getSmsVercode() {
      const { phone } = this;
      if (!phone) {
        this.$message({ message: "请输入手机号", type: "warning" });
        return;
      }
      if (!this.isPoneAvailable(phone)) {
        this.$message({ message: "请输入正确手机号", type: "warning" });
        return;
      }
      API.getSmsVercode(phone).then(() => {
        this.$message({ message: "验证码发送成功", type: "success" });
        this.smsSec = 60;
        this.timer = setInterval(() => {
          this.smsSec--;
          this.btnText = this.smsSec + "S 后重发";
          if (this.smsSec <= 0) {
            this.btnText = "重发验证码";
            clearInterval(this.timer);
          }
        }, 1000);
      });
    },
    smslogin() {
      const { phone, sms } = this;
      const shareCode = localStorage.getItem("share_code");
      if (!phone || !sms) {
        this.$message({ message: "请输入手机号和验证码", type: "warning" });
        return;
      }
      this.loginBySms({
        phone,
        sms,
        shareCode,
        redirect_url: this.redirect_route
      });
    },
    isPoneAvailable($poneInput) {
      return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test($poneInput);
    },
    wxLogin() {
      console.log(this.redirect_route, "微信登录");
      const url =
        "https://open.weixin.qq.com/connect/qrconnect?appid=" +
        process.env.VUE_APP_APPID +
        "&redirect_uri=" +
        encodeURIComponent(
          "http://www.rendanheyi.com.cn/api/callback?rUrl=" +
            window.location.origin +
            "/wx?redirect_url=" +
            this.redirect_route
        ) +
        "&response_type=code" +
        "&scope=snsapi_login" +
        "&state=goosevideo" +
        "&connect_redirect=1" +
        "#wechat_redirect";
      window.location.href = url;
    },
    platformAgree() {
      window.open("人单合一平台服务协议", "_blank");
    },
    contentManage() {
      window.open("人单合一平台内容管理规定", "_blank");
    },
    operationStandard() {
      window.open("人单合一平台运营规范", "_blank");
    },
    privacyagree() {
      window.open("人单合一隐私协议", "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/modules/login";

.login-protal {
  color: #8f9bb3;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  margin-top: 40px;

  .el-button {
    padding: 5px;
  }
}
</style>
