<template>
  <div class="box">
    <div class="class-img">
      <img :src="activeInfo.liveImg || activeInfo.coverUrl" />
    </div>

    <div
      class="live-pay"
      v-if="activeInfo.discountPrice > 0 || activeInfo.price > 0"
    >
      <!-- <el-button
        type="primary"
        round
        style="font-size: 16px;
  width: 276px;"
        >课程价格： &yen;{{ videoPrice }}</el-button
      > -->

      <div
        class="live-price"
        v-if="videoPrice > 0 && activeInfo.isBrought == false"
      >
        <div><span> 课程价格：</span></div>
        <Price
          :price="activeInfo.price"
          :discountPrice="activeInfo.discountPrice"
        />
      </div>
    </div>
    <div class="live-title">
      {{ activeInfo.liveTitle || activeInfo.title || activeInfo.columnTitle }}
    </div>

    <div class="info-item">
      <div class="time">
        <img src="../../assets/images/ico/时间选择器@3x.png" />
      </div>

      <span>课程时间：</span>
      {{
        activeInfo.liveBeginTime ||
          activeInfo.createTime | formatTime("YYYY-MM-DD HH:mm")
      }}
    </div>
    <div class="info-item">
      <div class="people">
        <img src="../../assets/images/ico/我的@3x.png" />
      </div>

      主办方/主讲人：{{
        activeInfo.liveLecturer ||
          activeInfo.teacher ||
          (activeInfo.teachersList
            ? activeInfo.teachersList.map(i => i.teacherName).join(",")
            : false) ||
          "无"
      }}
    </div>
    <!-- --------- -->
    <!-- <div class="info-item" v-if="id">
      <div class="info">
        <img src="../../assets/images/ico/商铺@2x.png" />
      </div>
      <span>店铺：</span>
      <div class="info-item-name" @click="backHome()">{{ name }}</div>
    </div> -->
    <div class="info-item">
      <div class="info"><img src="../../assets/images/ico/资讯@3x.png" /></div>

      <span>课程简介</span>
    </div>
    <div class="detail">
      <p>
        {{
          activeInfo.liveDescription ||
            activeInfo.description ||
            "&emsp;&emsp;【" +
              (activeInfo.liveTitle ||
                activeInfo.title ||
                activeInfo.columnTitle) +
              "】由“人单合一”直播平台全程提供直播服务支持。手机端一键轻松创建直播，培训、网课、会议等场景全覆盖，直播内容云端永久存储，一键下载，更有密码、红包、答题、抽奖、签到等功能助力线上直播营销。"
        }}
      </p>
    </div>
    <div
      v-show="
        userInfo.userRole == 'COMPANY' &&
          $route.path == '/coursemarket/mediacenter'
      "
    >
      <el-button
        v-if="
          (activeInfo.discountPrice !== 0 ||
            (activeInfo.discountPrice == null && activeInfo.price !== 0)) &&
            activeInfo.isBrought == false
        "
        style="width: 100%;margin-top:10px"
        type="primary"
        @click="buyCourse"
        >立即购买</el-button
      >
      <el-button
        v-else
        style="width: 100%;margin-top:10px"
        type="primary"
        :disabled="isAdd"
        @click="addCourseLib"
        >{{ isAdd ? "已加入课程库" : "+ 加入课程库" }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Price from "@/components/Price";
// import { addCourseLib } from "@/api/dashboard.js";
import { liveStatusMap } from "../../filters/index";
export default {
  data() {
    return {
      addLoading: false,
      // isAdd: this.$store.media.myCourseMarket,
      liveStatusMap
    };
  },
  components: {
    Price
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapState("home", ["shopName", "shopId"]),
    ...mapState("media", ["videoInfo", "activeInfo"]),
    name() {
      return this.shopName || this.videoInfo.shopInfo?.name;
    },
    joinShow() {
      if (this.name.length < 3) {
        return false;
      }
      const tempStr = this.name.substr(0, this.name.length - 3);

      return !(tempStr == this.userInfo.username);
    },
    id() {
      return this.shopId || this.videoInfo.shopInfo?.shopId;
    },

    ...mapState({
      isAdd: state => state.media.myCourseMarket,
      videoPrice() {
        return this.activeInfo.discountPrice >= 0 || this.activeInfo.price > 0;
        // if (
        //   this.$route.query.courseType == "rrs" &&
        //   this.activeInfo.price > 0
        // ) {
        //   return this.activeInfo.price;
        // }
      }
    })
  },
  methods: {
    backHome() {
      this.$router.push({
        name: "Shop",
        query: {
          shopId: this.id
        }
      });
    },

    buyCourse() {
      var payTarget = document.getElementById("pay");
      if (payTarget) {
        payTarget.style.display = "block";
      } else {
        alert("请等待视频生成后再购买！");
      }
    },

    async addCourseLib() {
      /**
       * 直播/录播/用户系列课 api_live 11
       */

      /**
       * 官方系列课 api_course  12
       */

      /**
       * 课师宝课程rrd_column  13
       */
      /**
       * OTT课程  14
       */

      this.addLoading = true;
      const { id } = this.activeInfo;
      const { courseType } = this.$route.query;
      const liveStatus = this.liveStatusMap(courseType);

      this.$store.dispatch("media/addCourseLib", { id, liveStatus });
      // console.log(SET_MY_COURSEMARKET);
      // await addCourseLib({ id, liveStatus });
      // this.$message.success("添加成功");
      // this.addLoading = false;
      // this.isAdd = true;

      // // console.log("isadd", this.isAdd);
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  color: #333;
  padding: 15px 16px 15px 18px;
}

.class-img img {
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  height: 161px;
  width: 276px;
}

.live-pay {
  margin-top: 17px;
}

.live-price {
  align-items: flex-end;
  color: #e07471;
  display: flex;
  font-size: 17px;
  font-weight: 600;
  line-height: 17px;
  opacity: 1;
}

.live-title {
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0;
}

.info-item {
  color: #020d23;
  font: 500 12px PingFang SC;
  line-height: 18px;
  margin: 0;
  margin-top: 12px;
  min-height: 18px;
  opacity: 0.8;
}

.info-item .time,
.info-item .people,
.info-item .info {
  float: left;
  height: 18px;
  margin-right: 10px;
  width: 18px;
}

.info-item span {
  float: left;
}

.info-item img {
  height: 100%;
  width: 100%;
}

.info-item-name {
  font-size: 12px;
  font-weight: 600;
  // line-height: 24px;
  color: #3377ff;
  opacity: 0.8;
  cursor: pointer;
}

.detail {
  background: #f4f6f9;
  border-radius: 10px;
  box-sizing: border-box;
  color: #020d23;
  font: 500 12px PingFang SC;
  // height: 179px;
  line-height: 24px;
  margin-top: 10px;
  padding: 20px 17px 22px 18px;
  width: 276px;

  img {
    width: 100%;
  }
}
</style>
