<template>
  <div class="contents">
    <div class="contents-border" v-show="lecturerData.invitationStatus == 0">
      <div class="contents-bg">
        <img src="../../assets/images/lecturer/讲师邀请背景@2x.png" alt="" />
      </div>
      <div class="contents-lecturer">
        <div class="lecturer-touxiang">
          <img :src="lecturerData.shopUserHeadImg" alt="" />
        </div>
        <div class="lecturer-name">{{ lecturerData.shopUserName }}</div>
        <div class="lecturer-text">
          邀请您成为【{{ lecturerData.shopName }}】的特邀讲师
        </div>
        <div class="lecturer-bottom" @click="accept()">
          <span>接受邀请</span>
        </div>
      </div>
    </div>
    <Success v-show="lecturerData.invitationStatus == 1" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Success from "./Success";
export default {
  components: {
    Success
  },
  data() {
    return {
      data: {}
    };
  },
  computed: {
    ...mapGetters(["userInfo", "isLogin"]),
    ...mapState("home", ["lecturerData"])
  },
  watch: {
    "lecturerData.invitationStatus"(val) {
      if (val === 1) {
        this.$router.push({ name: "InvitationSucceeded" });
      }
      if (val === -1) {
        this.$router.push("/used");
      }
    }
  },
  methods: {
    ...mapActions("home", ["getLecturerData", "acceptInvitation"]),
    accept() {
      let invitationId = this.lecturerData.invitationId;
      this.acceptInvitation({ invitationId });
    }
  },
  created() {
    const invitationId = this.$route.query.invitationId;
    const lectureId = this.userInfo?.id;
    this.getLecturerData({ invitationId, lectureId });
  }
};
</script>

<style lang="scss" scoped>
.contents {
  width: 100%;
  min-height: calc(100vh - 164px);
  width: 100%;
  z-index: -10;
  zoom: 1;

  .contents-border {
    // background: #ccc url(../../assets/images/lecturer/邀请背景@3x.png) no-repeat
    // fixed 0;
    background-size: cover;
    min-height: calc(100vh - 164px);
    display: flex;
    position: relative;
  }
  .contents-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 50px;
    img {
      width: 500px;
    }
  }

  .contents-lecturer {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;

    .lecturer-touxiang {
      padding-top: 222px;
      img {
        width: 150px;
        height: 150px;
        box-shadow: 0px 3px 5px 0px #bcc8e1;
        border-radius: 100px;
      }
    }

    .lecturer-name {
      margin: auto;
      padding-top: 20px;
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 180px;
    }

    .lecturer-text {
      margin: auto;
      padding-top: 67px;
      max-width: 264px;
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      color: #1f2e4d;
      opacity: 1;
    }

    .lecturer-bottom {
      top: 30px;
      background: #3b7cff;
      border-radius: 40px;
      padding: 6px 94px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      position: relative;
      display: table;
      margin: auto;
      cursor: pointer;
    }
  }
}
</style>
