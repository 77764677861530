import pwform from "./index.html";
import "./index.scss";

function parseDom(html) {
  let ele = document.createElement("div");
  ele.innerHTML = html;
  return ele.childNodes[0];
}

/**
 * 密码组件
 */
export default class PlayPwdComponent {
  /**
   * @constructor 密码构造函数
   * @param {Array playlist 播放列表数组}
   */
  constructor(password) {
    this.pwdHtml = parseDom(pwform);
    this.getPwd = password;
    this.passwordConfirm = false;
  }

  createEl(el) {
    el.appendChild(this.pwdHtml);
  }

  ready(player) {
    const password = this.getPwd();
    // const password = "111";
    if (password) {
      this.pwdHtml.style.display = "flex";
      player.pause();
    } else {
      this.pwdHtml.style.display = "none";
      player.play();
    }
    this.pwdHtml.querySelector(".playpwd-container > ul > .pwd > input").value =
      "";
    let inputValue;
    this.pwdHtml.querySelector(
      ".playpwd-container > ul > .pwd > input"
    ).oninput = e => {
      inputValue = e.target.value;
    };

    this.pwdHtml.querySelector(
      ".playpwd-container > ul > .confirm-btn > button"
    ).onclick = () => {
      console.log("=====" + inputValue);
      if (password === inputValue) {
        player.play();
        this.pwdHtml.style.display = "none";
      } else {
        player.pause();
        this.pwdHtml.style.display = "flex";
        this.pwdHtml.querySelector(
          ".playpwd-container > ul > .tool-tip > p"
        ).innerHTML = "密码错误";
      }
    };
  }
}
