<template>
  <div class="container">
    <ShopBanner />
    <FirstList />
    <SecondList />
    <ThirdList />
  </div>
</template>

<script>
import ShopBanner from "./ShopBanner";
import FirstList from "./FirstList";
import SecondList from "./SecondList";
import ThirdList from "./ThirdList";
import { mapActions } from "vuex";
export default {
  components: {
    ShopBanner,
    FirstList,
    SecondList,
    ThirdList
  },
  data() {
    return {
      shop: false
    };
  },
  methods: {
    ...mapActions("home", ["getShopData"])
  },
  created() {
    this.getShopData(this.$route.query.shopId);
  }
};
</script>

<style lang="scss" scoped>
.hot-box {
  display: flex;
  justify-content: space-between;
}
</style>
