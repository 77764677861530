import html from "./index.html";
import "./index.scss";

function parseDom(html) {
  let ele = document.createElement("div");
  ele.innerHTML = html;
  return ele.childNodes[0];
}

/**
 *  状态组件
 */
export default class PlayStatusComponent {
  constructor(price, free, duration, password) {
    this.Html = parseDom(html);
    this.price = price;
    this.free = free;
    this.duration = duration;
    this.password = password;
  }
  // 实例的时候调用，设置的初始参数在构建对象时，会传递给init方法
  init() {}

  // 播放器创建完成，可以调用播放器的API了
  created() {}
  // 创建组件的UI， 参数为播放器容器的div
  createEl(el) {
    el.appendChild(this.Html);
  }

  // 视频可播放状态
  ready(player) {
    console.log(player);
    if (this.price == 0) {
      console.log("已购买或者课程免费");
      player.play();
    } else if (this.free == true) {
      console.log("可以试听");
      player.play();
    } else {
      console.log("不能试听");
      player.pause();
    }
    if (this.password) {
      console.log("有密码！！！");
      player.pause();
      console.log("暂停播放");
    }
  }

  // 播放出错
  error() {
    // alert("player" + player);
    console.log("已购买播放报错");
    this.Html.style.display = "flex";
  }

  // 开始播放
  play(player, e) {
    console.log(player, e);
  }

  // 播放暂停
  pause(player, e) {
    console.log(player, e);
  }

  // 正在播放
  playing(player, e) {
    console.log(player, e);
  }

  // 等待数据
  waiting() {}

  // 播放结束
  ended(player, e) {
    console.log(player, e);
  }

  // 播放器销毁
  dispose() {}

  //播放事件改变，通过第二各参数的timestamp属性得到播放时间
  timeupdate() {}
}
