<template>
  <div class="banner">
    <swiper
      ref="mySwiper"
      :options="swiperOptions"
      class="swiper-box"
      v-if="bannerList.length > 0"
    >
      <swiper-slide :key="index" v-for="(banner, index) in bannerList">
        <div
          class="banner-image"
          :style="{
            backgroundImage: 'url(' + (banner.url || banner.imgSy) + ')'
          }"
        ></div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  props: {
    shop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      banners: ["1", "2", "3", "4", "5"],
      swiperOptions: {
        loop: this.loop,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    };
  },
  computed: {
    bannerList() {
      return this.$store.state.home.bannerList;
    },
    loop() {
      if (this.bannerList.length > 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.banner {
  border-radius: 15px;
  height: 500px;
  margin-top: 50px;
  overflow: hidden;
}

.swiper-box {
  height: 100%;
  width: 100%;
}

.banner-image {
  background-position: center;
  background-size: cover;
  cursor: pointer;
  height: 100%;
}

::v-deep {
  .swiper-pagination-bullet {
    background: #fff;
    border-radius: 5px;
    height: 8px;
    width: 40px;
  }

  .swiper-pagination-bullet-active {
    background: #fff;
    border-radius: 5px;
    height: 8px;
    width: 40px;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 24px;
  }
}

.swiper-banner {
  cursor: pointer;
  width: 100%;
}
</style>
