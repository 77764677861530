<template>
  <div class="container bannerlist">
    <Breadcrumb :title="BreadcrumbTitle" class="breadcrumb" />
    <div
      @click="openUrl"
      class="bannerlist_banner"
      :style="{
        backgroundImage: 'url(' + banner.imgZq + ')'
      }"
    ></div>
    <div class="bannerlist_screen">
      <div>
        <el-button round @click="changeOrd(null)" :class="{ ord: ord == null }"
          >默认</el-button
        >
        <el-button round @click="changeOrd(2)" :class="{ ord: ord == 2 }"
          >热度</el-button
        >
        <el-button round @click="changeOrd(3)" :class="{ ord: ord == 3 }"
          >时间</el-button
        >
      </div>
    </div>
    <div class="bannerlist_table">
      <el-row :gutter="32" style="margin-top: -16px;">
        <el-col :span="6" v-for="(item, index) in live" :key="index">
          <VideoCard
            theme="lovely"
            style="margin-top: 40px;"
            :data="item"
            :nextRouteName="nextRouteName"
          />
        </el-col>
      </el-row>
      <div class="paginate">
        <el-pagination
          layout="prev, pager, next"
          background
          :current-page="page"
          :page-count="pages"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb";
import VideoCard from "../../components/VideoCard";

import { mapActions, mapState } from "vuex";
export default {
  props: ["id"],
  components: {
    Breadcrumb,
    VideoCard
  },
  data() {
    return {
      page: 1,
      order: "",
      BreadcrumbTitle: "Banner专区",
      ord: null
    };
  },
  computed: {
    ...mapState("banner", ["banner", "live", "pages"]),
    terminal() {
      const routePath = this.$route.path;
      if (routePath.slice(1, 11) == "bannerlist") {
        return 3;
      } else {
        return 2;
      }
    },
    nextRouteName() {
      const routePath = this.$route.path;
      if (routePath.slice(1, 11) == "bannerlist") {
        return "rdhyMedia";
      } else {
        return "Media";
      }
    }
  },
  methods: {
    ...mapActions("banner", ["getBannerData"]),
    openUrl() {
      if (this.banner.urlZqAble == 1) {
        window.open(this.banner.urlZq);
      }
    },
    changeOrd(o) {
      this.ord = o;
      this.page = 1;
      const { id, page, ord, terminal } = this;
      this.getBannerData({ id, page, ord, terminal });
    },
    changePage(p) {
      this.page = p;
      const { id, page, ord, terminal } = this;
      this.getBannerData({ id, page, ord, terminal });
    }
  },
  created() {
    const { id, page, ord, terminal } = this;
    this.getBannerData({ id, page, ord, terminal });
  }
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  padding: 25px 0;
}

.bannerlist_banner {
  background: #d8d8d8;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  height: 500px;
  opacity: 1;
}

.bannerlist_screen {
  align-items: center;
  display: flex;
  padding-top: 27px;
}

.bannerlist_screen_label {
  color: #1f2e4d;
  font-size: 25px;
  font-weight: 600;
  line-height: 45px;
}

.bannerlist_table {
  padding: 0 0 20px;
}

.paginate {
  margin-top: 70px;
  text-align: center;
}

.ord {
  background: #37f;
  color: #fff;
}
</style>
