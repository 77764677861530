<template>
  <div class="empty">
    <div class="empty-bg">
      <img src="@/assets/images/background/空状态.png" alt="" />
    </div>
    <div class="empty-text">
      <p>您还没有历史记录哦~</p>
      <p>快去逛逛吧</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.empty-bg {
  padding: 408px 440px 42px 436px;
}

.empty-bg img {
  height: 173px;
  width: 278px;
}

.empty-text {
  color: #1f2e4d;
  font-size: 16px;
  margin-left: 105px;
  text-align: center;
}
</style>
