<template>
  <header>
    <nav class="container box">
      <div class="header_left">
        <div class="logo_pic" @click="homepage">
          <img src="@/assets/images/shop/店铺.png" alt="" />
        </div>
        <div class="top_label" @click="homepage">
          <div class="top_label_text">
            <span>{{ name }}</span>
          </div>
          <!-- <div class="top_label_line" v-show="line"></div> -->
        </div>
      </div>
      <div class="header_right">
        <!-- <div
          class="app_download_button"
          style="margin: 15px 0;"
          @click="update()"
        >
          <span>上传课程</span>
        </div> -->

        <div class="app_download">
          <div
            class="app_download_button"
            @mouseover="mouseover"
            @mouseleave="mouseleave"
          >
            <span>下载APP</span>
          </div>
          <!-- APP二维码 -->
          <div class="QR_code" v-show="code">
            <div class="code_words">
              <span>扫码下载人单合一APP </span>
            </div>
            <div class="code_pic">
              <img src="@/assets/images/code.png" alt="" />
            </div>
          </div>
        </div>
        <!-- <div class="download-app">
          <button>
            下载APP
          </button>
        </div> -->
        <div
          v-if="!!userInfo"
          class="header_user"
          @mouseover="menuover"
          @mouseleave="menuleave"
        >
          <div class="header_user_box">
            <div class="header_user_pic">
              <img
                :src="
                  userInfo.headImg || require('@/assets/images/touxiang@2x.png')
                "
              />
            </div>
            <div class="header_user_login_button">
              <span>{{ userInfo.username }}</span>
            </div>
          </div>

          <div class="header_user_menu" v-show="menu">
            <!-- <div
              class="header_user_menu_item"
              @click="
                $router.push({
                  name: 'RecentStudy',
                  query: {
                    shopId: $route.query.shopId
                  }
                })
              "
            >
              <div class="header_user_menu_item_label">
                <span>个人中心</span>
              </div>
            </div> -->
            <div class="header_user_menu_item" @click="myLoginOut">
              <div class="header_user_menu_item_label">
                <span>退出登录</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="header_user_login">
          <div class="header_user_login_touxiang">
            <img src="@/assets/images/touxiang@2x.png" alt="" />
          </div>
          <div class="header_user_login_button" @click="login()">
            <span>未登录</span>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      tip: false,
      line: true,
      code: false,
      menu: false
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapState("home", ["shopName", "shopId"]),
    ...mapState("media", ["videoInfo"]),
    name() {
      return this.shopName || this.videoInfo.shopInfo?.name;
    },
    id() {
      return this.shopId || this.videoInfo.shopInfo?.shopId;
    }
  },
  methods: {
    ...mapActions(["loginOut"]),
    homepage() {
      this.$router.push({
        name: "Shop",
        query: {
          shopId: this.id || this.$route.query.shopId
        }
      });
    },
    myLoginOut() {
      // 退出登录埋点
      const data = {
        eventId: "6",
        userId: this.userinfo && this.userinfo.id
      };
      this.loginOut({ data });
    },
    openTip() {
      this.tip = true;
    },
    closeTip() {
      this.tip = false;
    },
    // 鼠标移入
    mouseover() {
      this.code = true;
    },
    // 鼠标移除
    mouseleave() {
      this.code = false;
    },
    menuover() {
      this.menu = true;
    },
    menuleave() {
      this.menu = false;
    },
    update() {
      // window.open(window.location.origin + "/up", "_blank");
      this.$router.push("/up");
    },
    login() {
      this.$router.replace({
        path: "/login",
        query: {
          redirect_route: this.$route.fullPath
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  background: var(--header-background);
  background-size: cover;
  box-shadow: 0 6px 12px 0 rgba(230, 233, 240, 0.3);
  position: relative;
  z-index: 1000;
}

.box {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.header_left {
  align-items: center;
  display: flex;
  // padding-left: 57px;
}

.logo_pic {
  cursor: pointer;
  // margin-top: 18px;
}

.logo_pic img {
  width: 51px;
}

.logo_title {
  margin-left: 12px;
  margin-top: 14px;
}

.logo_title_cn {
  color: #03152a;
  display: flex;
  font-size: 26px;
  font-weight: bold;
  height: 37px;
  line-height: 37px;
  text-shadow: 0 2px 4px rgba(143, 150, 163, 0.2);
  width: 104px;
}

.logo_title_zh {
  color: #03152a;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  text-shadow: 0 2px 4px rgba(143, 150, 163, 0.2);
  width: 101px;
}

.top_label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-left: 7px;
}

.top_label_text {
  color: #1f2e4d;
  color: var(--header-color);
  display: flex;
  font-size: 20px;
  font-weight: 500;
  // margin-top: 24px;
  text-shadow: 0 2px 4px rgba(143, 150, 163, 0.2);
}

.top_label_line {
  border-bottom: 3px solid #37f;
  box-shadow: 0 2px 4px 0 rgba(143, 150, 163, 0.2);
  margin: 20px 9px 0;
}

.header_right {
  align-items: center;
  display: flex;
  height: 80px;
  // padding-right: 97px;
}

.header_right .download-app {
  margin-right: 56px;
}

.download-app button {
  background: #37f;
  border: none;
  border-radius: 25px;
  color: #fff;
  font: 20px PingFangSC-Semibold;
  height: 50px;
  width: 110px;
}

.app_download {
  float: right;
  position: relative;
}

.app_download_button {
  background: var(--header-button-background);
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 rgba(143, 150, 163, 0.2);
  cursor: pointer;
  display: flex;
  height: 50px;
  margin: 15px 45px;
  width: 110px;
}

.app_download_button span {
  // font-weight: 600;
  color: var(--header-button-color);
  font-size: 18px;
  height: 24px;
  line-height: 14px;
  padding: 18px 10px 8px 20px;
  text-shadow: 0 2px 4px rgba(143, 150, 163, 0.2);
  width: 80px;
}

.tip {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(143, 150, 163, 0.2),
    4px -2px 10px 0 rgba(143, 150, 163, 0.2);
  color: #8f9bb3;
  font-size: 18px;
  font-weight: 600;
  left: -40%;
  padding: 10px;
  position: absolute;
  width: 200px;
}

.QR_code {
  // margin-top: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(143, 150, 163, 0.2),
    4px -2px 10px 0 rgba(143, 150, 163, 0.2);
  position: absolute;
}

.code_words {
  color: #8f9bb3;
  font-size: 18px;
  font-weight: 500;
  height: 25px;
  line-height: 25px;
  padding: 17px 10px 0;
  text-align: center;
  text-shadow: 0 2px 4px rgba(143, 150, 163, 0.2);
  width: 180px;
}

.code_pic {
  padding: 0 25px 11px;
}

.code_pic img {
  height: 150px;
  width: 150px;
}

.header_user {
  align-items: center;
  position: relative;
  top: 4px;
  // width: 58px;
}

.header_user_box {
  align-items: center;
  display: flex;
}

// .header_user_pic {
//   width: 100%;
// }

.header_user_pic img {
  border-radius: 50%;
  height: 58px;
  width: 58px;
}

.header_user_name {
  display: flex;
  padding-left: 8px;
}

.header_user_menu {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(143, 150, 163, 0.2),
    4px -2px 10px 0 rgba(143, 150, 163, 0.2);
  left: -70px;
  overflow: hidden;
  position: absolute;
  width: 200px;
}

.header_user_menu_item {
  cursor: pointer;
  height: 50px;

  &:hover {
    background: #ecf3ff;
  }
}

.header_user_menu_item_label {
  color: #8f9bb3;
  font-size: 18px;
  font-weight: 600;
  height: 25px;
  line-height: 25px;
  padding: 14px 63px 12px 65px;
  text-shadow: 0 2px 4px rgba(143, 150, 163, 0.2);
  width: 72px;

  &:hover {
    color: #37f;
  }
}

.header_user_login {
  align-items: center;
  display: flex;
  padding-top: 4px;
}

.header_user_login_touxiang img {
  width: 51px;
  height: 51px;
  background: var(--header-avatar-background);
  background-size: cover;
}

.header_user_login_button {
  max-width: 130px;
  overflow: hidden;
  // display: flex;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  cursor: pointer;
}

.header_user_login_button span {
  color: var(--header-color);
  font-size: 18px;
}

a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}
</style>
