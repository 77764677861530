<template>
  <div>
    <div
      class="player-duration"
      v-if="duration > 0 && isBrought == false && free == true"
    >
      <span>{{ duration / 60000 }}分钟试听</span>
    </div>
    <div
      class="player-free"
      v-if="duration == 0 && isBrought == false && free == true"
    >
      <span>免费试听</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    duration: {
      type: Number,
      default: 0
    },
    isBrought: {
      type: Boolean,
      default: false
    },
    free: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.player-duration {
  border: 1px solid #92d643;
  border-radius: 20px;
  color: #92d643;
  display: flex;
  font-size: 12px;
  // min-width: 64px;
  padding: 5px;
}

.player-free {
  border: 1px solid #3b7cff;
  border-radius: 20px;
  color: #3b7cff;
  display: flex;
  font-size: 12px;
  min-width: 48px;
  padding: 5px;
}
</style>
