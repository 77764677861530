<template>
  <div class="box">
    <div
      class="pdfitem"
      v-for="(item, index) in list"
      :key="index"
      @click="showPdf(item)"
    >
      <span>
        <img class="pdf-button" src="@/assets/images/pdf/pdf@2x.png" alt="" />
      </span>
      <div class="info">
        <div class="title">{{ item.name }}</div>
        <div class="size">{{ item.size | fileSize }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: mapState({
    activeCourse: state => state.media.activeCourse,
    pdfInfo: state => state.media.pdfInfo,
    list() {
      return this.pdfInfo || [];
    }
  }),
  filters: {
    fileSize(val) {
      console.log("val", val);
      if (!val) return "";
      if (val < 102.4) {
        return val + " B";
      }
      let num = val / 1024;
      if (num > 1024) {
        num = num / 1024;
        return num.toFixed(2) + " M";
      } else {
        return num.toFixed(2) + " K";
      }
    }
  },
  methods: {
    ...mapActions("media", ["getPdf"]),
    showPdf(item) {
      window.open(
        process.env.VUE_APP_HTTP + "/pdf/web/viewer.html?file=" + item.url
      );
      //   this.$router.push({ name: "PdfViewer", params: { url: item.url } });
    }
  },
  mounted() {
    const { id, courseType } = this.$route.query;
    this.getPdf({ id, courseType });
  }
};
</script>

<style lang="scss" scoped>
.box {
  padding: 10px;
}

.pdfitem {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  margin: 0 -10px;
  padding: 10px;

  &:hover {
    background: #fff;
  }

  .pdf-button {
    height: 23px;
  }

  .info {
    padding: 0 15px;

    .size {
      color: #020d23;
      font-size: 10px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .title {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
  }

  .sub {
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin-top: 10px;
  }
}

.bottom-img {
  margin-top: 20px;
  text-align: center;

  img {
    width: 137px;
  }
}
</style>
