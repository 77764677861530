<template>
  <div>
    <div v-if="livePersonalPayList.length == 0">
      <Empty />
    </div>
    <el-row :gutter="32">
      <el-col
        :span="8"
        v-for="(item, index) in livePersonalPayList"
        :key="index"
      >
        <Paycard :data="item" />
      </el-col>
    </el-row>
    <div class="paginate" v-if="livePersonalPayList.length > 0">
      <el-pagination
        layout="prev, pager, next"
        background
        @current-change="changePage"
        :page-count="pages"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Paycard from "../../components/PayCard";
import Empty from "../../components/Empty";
export default {
  components: {
    Paycard,
    Empty
  },
  data() {
    return {
      page: 1
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    livePersonalPayList() {
      return this.$store.state.userCenter.orders;
    },
    pages() {
      return this.$store.state.userCenter.pages;
    },
    pageSize() {
      return this.$store.state.userCenter.pageSize;
    }
  },
  methods: {
    ...mapActions(["getOrderData"]),
    changePage(page) {
      this.page = page;
      this.getOrderData(this.page);
    }
  },
  updated() {
    // console.log("333333333", this.livePersonalPayList);
  },
  mounted() {
    this.getOrderData(this.page);
  }
};
</script>

<style lang="scss" scoped>
.paginate {
  margin-top: 30px;
  text-align: center;
}
</style>
