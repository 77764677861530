<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>
        <div class="back" @click="back()">
          <div class="back-ico"></div>
          <span>返回</span>
        </div>
      </el-breadcrumb-item>
      <!-- <el-breadcrumb-item>
        <span>{{ title }}</span>
      </el-breadcrumb-item> -->
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: ["title"],
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.back {
  cursor: pointer;
  display: flex;
}

.back-ico {
  background-image: url("../assets/images/ico/路径.png");
  float: left;
  height: 19px;
  width: 12px;
}

.back span {
  color: var(--breadcrumb-color);
  // font: 400 20px PingFang SC;
  font-size: 18px;
  line-height: 17px;
  margin-left: 15px;
  opacity: 1;
}
</style>
