<template>
  <div class="container usercenter">
    <Breadcrumb :title="BreadcrumbTitle" class="breadcrumb" />
    <div class="usercenter_table">
      <el-container>
        <el-aside
          width="300px"
          style="background: #fff;
    margin-bottom: 20px;"
        >
          <div class="usercenter_table_left">
            <div class="usercenter_table_left_touxiang">
              <img
                :src="
                  userInfo.headImg || require('@/assets/images/touxiang@2x.png')
                "
                alt=""
              />
            </div>
            <div class="usercenter_table_left_name">
              <span>{{ userInfo.username }}</span>
            </div>
            <div>
              <el-menu
                default-active="RecentStudy"
                mode="vertical"
                style="width: 299px;"
              >
                <el-menu-item
                  index="RecentStudy"
                  @click="$router.push({ name: 'RecentStudy' })"
                >
                  最近学习
                </el-menu-item>
                <!-- <el-menu-item index="2">
                  <span slot="title">最近开课</span>
                </el-menu-item> -->
                <el-menu-item
                  index="Purchase"
                  @click="$router.push({ name: 'Purchase' })"
                >
                  我购买的
                </el-menu-item>
              </el-menu>
            </div>
          </div>
        </el-aside>
        <el-main>
          <div class="usercenter_table_right">
            <router-view />
            <!-- <div class="usercenter_table_right_list"> -->
            <!-- <Video
            v-for="(item, index) in livePersonalPageVOList"
            :key="index"
            :data="item"
            theme="usercenter"
          /> -->
            <!-- </div> -->
            <!-- <el-row :gutter="32">
              <el-col
                :span="8"
                v-for="(item, index) in livePersonalPageVOList"
                :key="index"
              >
                <Video theme="lovely" :data="item" />
              </el-col>
            </el-row>
            <div class="paginate">
              <el-pagination
                layout="prev, pager, next"
                background
                @current-change="changePage"
                :page-count="pages"
              >
              </el-pagination>
            </div> -->
          </div>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Breadcrumb from "../../components/Breadcrumb";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      page: 1,
      BreadcrumbTitle: "最近学习"
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    livePersonalPageVOList() {
      return this.$store.state.userCenter.livePersonalPageVOList;
    },
    pages() {
      return this.$store.state.userCenter.pages;
    },
    pageSize() {
      return this.$store.state.userCenter.pageSize;
    }
  },
  methods: {
    ...mapActions(["getCurrentData"]),
    changePage(page) {
      this.page = page;
      this.getCurrentData(this.page);
    }
  },
  mounted() {
    this.getCurrentData(this.page);
  }
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  padding: 25px 0;
}

.usercenter_table {
  display: flex;
}

.usercenter_table_left {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 100%;
}

.usercenter_table_left_touxiang {
  padding-top: 45px;
}

.usercenter_table_left_touxiang img {
  border-radius: 50%;
  width: 80px;
}

.usercenter_table_left_name {
  color: #1f2e4d;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  padding: 16px 20px;
  text-align: center;
}

.usercenter_table_right {
  height: calc(100vh - 50px);
}

.usercenter_table_right_title {
  color: #3d4966;
  display: flex;
  font-size: 24px;
  font-weight: 500;
  justify-content: space-between;
  line-height: 33px;
}

.usercenter_table_right_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0 12px;
}

::v-deep {
  .el-menu-item {
    font-size: 16px;
    text-align: center;
  }
}
</style>
