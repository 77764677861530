<template>
  <div class="more">
    <div class="top-panel">
      <div class="container">
        <Breadcrumb title="精选课程" class="breadcrumb " />
        <el-button
          v-for="(item, index) in positionList"
          :key="index"
          class="top-btn"
          :class="{ active: active == item.positionId }"
          type="text"
          @click="changeActive(item.positionId)"
          >{{ item.title }}</el-button
        >
        <!-- <el-button
          v-if="positionList.length > 0"
          class="top-btn"
          :class="{ active: active == positionId.courseListId }"
          type="text"
          @click="changeActive(positionId.courseListId)"
          >{{ positionMap[positionId.courseListId] }}</el-button
        >
        <el-button
          v-if="positionList.length > 1"
          class="top-btn"
          :class="{ active: active == positionId.lovelyListId }"
          type="text"
          @click="changeActive(positionId.lovelyListId)"
        >
          {{ positionMap[positionId.lovelyListId] }}</el-button
        >
        <el-button
          v-if="positionList.length > 2"
          class="top-btn"
          :class="{ active: active == positionId.topListId }"
          type="text"
          @click="changeActive(positionId.topListId)"
        >
          {{ positionMap[positionId.topListId] }}</el-button
        >
        <el-button
          v-if="positionList.length > 3"
          class="top-btn"
          :class="{ active: active == positionId.caseListId }"
          type="text"
          @click="changeActive(positionId.caseListId)"
        >
          {{ positionMap[positionId.caseListId] }}</el-button
        > -->
      </div>
      <div></div>
    </div>
    <div class="container more-box">
      <el-button :class="{ ord: ord == null }" round @click="changeOrd(null)"
        >默认</el-button
      >
      <el-button :class="{ ord: ord == 3 }" round @click="changeOrd(3)"
        >最新</el-button
      >
      <el-button :class="{ ord: ord == 2 }" round @click="changeOrd(2)"
        >热门</el-button
      >
      <el-row :gutter="32">
        <el-col :span="6" v-for="(item, index) in list" :key="index">
          <VideoCard
            theme="lovely"
            style="margin-top: 30px;"
            :data="item"
            :nextRouteName="nextRouteName"
          />
        </el-col>
      </el-row>
      <!-- <div class="video-box">
        <VideoCard
          theme="home"
          style="margin-top: 30px;"
          v-for="(item, index) in list"
          :key="index"
          :data="item"
        />
      </div> -->
      <div class="paginate">
        <el-pagination
          layout="prev, pager, next"
          background
          :current-page="page"
          @current-change="changePage"
          :page-count="pages"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb";
import VideoCard from "../../components/VideoCard";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    terminal: {
      type: Number,
      default: 2
    }
  },
  components: {
    Breadcrumb,
    VideoCard
  },
  data() {
    return {
      page: 1,
      ord: null,
      active: null
    };
  },
  computed: {
    list() {
      return this.$store.state.more.list;
    },
    pages() {
      return this.$store.state.more.pages;
    },
    ...mapState("home", ["positionList", "positionMap", "positionId"]),
    nextRouteName() {
      const routePath = this.$route.path;
      if (routePath.slice(1, 9) == "morelist") {
        return "rdhyMedia";
      } else {
        return "Media";
      }
    }
  },
  methods: {
    ...mapActions(["getMoreData"]),
    ...mapActions("home", ["getHomeData"]),
    changePage(p) {
      this.page = p;
      const { active, page, ord, terminal } = this;
      this.getMoreData({ active, page, ord, terminal });
    },
    changeActive(a) {
      this.active = a;
      this.page = 1;
      const { active, page, ord, terminal } = this;
      this.getMoreData({ active, page, ord, terminal });
    },
    changeOrd(arg) {
      this.ord = arg;
      this.page = 1;
      const { active, page, ord, terminal } = this;
      this.getMoreData({ active, page, ord, terminal });
    }
  },
  created() {
    if (this.positionList.length == 0) {
      this.getHomeData(this.terminal);
    }
    this.active = this.$route.params.tab;
    const { active, page, ord, terminal } = this;
    this.getMoreData({ active, page, ord, terminal });
  }
};
</script>

<style lang="scss" scoped>
.more {
  height: 100%;
  min-height: calc(100vh - 164px);
}

.breadcrumb {
  padding: 25px 0;
}

.more-box {
  padding-bottom: 45px;
  padding-top: 32px;
}

.top-panel {
  // background: #fff;
  height: 129px;
}

.top-btn {
  color: #3d4966;
  font-size: 18px;
  line-height: 14px;
  margin-right: 40px;
}

.top-btn:hover,
.active {
  color: #37f;
}

.ord {
  background: #37f;
  color: #fff;
}
// .video-box {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   margin-top: 22px;
// }

.paginate {
  margin-top: 70px;
  text-align: center;
}
</style>
