<template>
  <div
    v-if="
      $store.state.home.thirdList.courseList !== undefined &&
        $store.state.home.thirdList.courseList.length > 0
    "
  >
    <div class="home-title">
      <span>{{ headline }}</span>
      <!-- <span
        class="home-more"
        @click="$router.push({ name: 'More', params: { tab: 4 } })"
      >
        MORE
        <img :src="require('@/assets/images/路径@2x.png')" />
      </span> -->
    </div>
    <div class="home-case">
      <VideoCard
        nextRouteName="ShopMedia"
        theme="lovely"
        v-for="(item, index) in list"
        :data="item"
        :key="index"
      />
      <div class="pick" v-show="listValue == 2"></div>
    </div>
  </div>
</template>

<script>
import VideoCard from "../../components/VideoCard";

export default {
  data() {
    return {
      typeValueMap: {
        1: "直播课",
        2: "系列课",
        3: "录播课"
      }
    };
  },
  components: {
    VideoCard
  },
  computed: {
    list() {
      return this.$store.state.home.thirdList.courseList;
    },
    listValue() {
      return this.list.length % 4;
    },
    headline() {
      return this.typeValueMap[this.$store.state.home.thirdList.typeValue];
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../assets/styles/modules/home";

.home-case:after {
  content: "";
  width: 323px;
}

.pick {
  width: 323px;
  overflow: hidden;
}

.home-case {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}
</style>
