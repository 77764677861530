<template>
  <div class="dashbord-container">
    <el-container>
      <el-aside width="250px" class="aside">
        <div class="logo">
          <img src="@/assets/images/logo@3x@2x.png" alt="" />
        </div>
        <el-menu
          :style="{
            border: 'none'
          }"
          router
          class="el-menu-vertical-demo"
          background-color="#052069"
          text-color="#939399"
          active-text-color="#fff"
          default-active="$route.path"
        >
          <template v-for="(item, index) in menus">
            <el-submenu :index="index + ''" :key="index">
              <template slot="title">
                <img :src="item.icon" class="menu-icon" alt="" />
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item
                v-for="submenu in item.subItems"
                :key="submenu.path"
                :index="submenu.path"
              >
                {{ submenu.meta.title }}
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
        <div class="app-download">
          <!-- app download -->
          <canvas class="canvas" height="64px" width="177px" id="pag"></canvas>
        </div>
      </el-aside>
      <el-container>
        <el-header class="header" height="90">
          <nav>
            <div class="avator">
              <img :src="userInfo && userInfo.headImg" />
              <span class="username">
                {{ userInfo && userInfo.username }}
              </span>
              <div
                class="userRole"
                :style="{
                  background:
                    userInfo.userRole == 'COMPANY'
                      ? 'linear-gradient(180deg, #528ae3 0%, #0068ff 98%)'
                      : 'linear-gradient(180deg, #f0952c 0%, #ffb833 100%)'
                }"
                @click="openDialog()"
              >
                {{ roleTypeMap(userInfo.userRole) }}
                <i class="el-icon-arrow-down"></i>
              </div>
            </div>

            <span class="logout">
              <el-button
                v-show="userInfo.userRole == 'ORGANIZATION'"
                size="small"
                type="primary"
                @click="open"
                icon="el-icon-s-home"
              >
                访问主页
              </el-button>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-switch-button"
                plain
                @click="logout"
              >
                退出登录
              </el-button>
            </span>
          </nav>
        </el-header>
        <el-main>
          <div class="main">
            <Breadcrumb :breadcrumbs="breadcrumbs" />
            <router-view />
          </div>
          <Footer class="footer" />
        </el-main>
      </el-container>
    </el-container>

    <el-dialog title="访问主页" :visible.sync="dialogFormVisible" width="600px">
      <div><span style="color: red"> * </span> 复制链接打开机构主页:</div>
      <div>
        <span id="pcshopurl">{{ urlPC }}</span>

        <el-button
          size="small"
          type="primary"
          style="margin-left: 20px"
          data-clipboard-action="copy"
          data-clipboard-target="#pcshopurl"
          plain
          class="copy-button"
          @click="copy"
          >复制</el-button
        >
      </div>
      <div style="margin-top: 20px">
        <span style="color: red"> * </span>
        手机扫码也可直接分享至微信/QQ等平台:
      </div>

      <div>
        <img :src="qrcodeUrl" alt="" srcset="" />
      </div>
    </el-dialog>
    <!-- 角色切换 -->
    <el-dialog title="切换版本" :visible.sync="dialogVisible" width="1000px">
      <div class="changeButton">
        <div class="changeButton-item" @click="changeRole('ORGANIZATION')">
          <img src="@/assets/images/dashboard/icon/机构版切换.png" alt="" />
        </div>
        <div class="changeButton-item" @click="changeRole('COMPANY')">
          <img src="@/assets/images/dashboard/icon/企业版切换.png" alt="" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Breadcrumb from "./Breadcrumb.vue";
import Footer from "../layout/Foot.vue";
// import { PAGInit } from "libpag";
import { roleTypeMap } from "../../filters";
import { getShopInfo } from "@/api/dashboard.js";
import Clipboard from "clipboard";
import QRCode from "qrcode";
import { changeUserRole } from "../../api/dashboard";
import store from "@/store";

export default {
  components: {
    Breadcrumb,
    Footer
  },
  data() {
    return {
      roleTypeMap,
      dialogFormVisible: false,
      shopId: null,
      qrcodeUrl: null,
      dialogVisible: false
    };
  },
  methods: {
    ...mapActions(["loginOut", "getUserInfo"]),
    logout() {
      this.loginOut();
      this.$router.replace({
        path: "/login",
        query: {
          redirect_route: this.$route.fullPath
        }
      });
    },
    open() {
      this.dialogFormVisible = true;
    },
    initQrCode() {
      QRCode.toDataURL(this.urlMobile).then(url => {
        this.qrcodeUrl = url;
      });
    },
    async getData() {
      const res = await getShopInfo(this.params);
      this.shopId = res.shopId;
      this.initQrCode();
    },
    copy() {
      var clipboard = new Clipboard(".copy-button");
      console.log(clipboard);
      clipboard.on("success", () => {
        console.log(11111);
        this.$message({ message: "复制成功", type: "success" });
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$message({ message: "该浏览器不支持复制", type: "warning" });
        clipboard.destroy();
      });
    },
    openDialog() {
      this.dialogVisible = true;
    },
    async changeRole(role) {
      await changeUserRole({ userRole: role });
      this.dialogVisible = false;
      this.getUserInfo();
      if (role == "COMPANY") {
        this.$router.push("/index");
      } else {
        this.$router.push("/organization_index");
      }
    }
  },

  mounted() {
    // PAGInit({
    //   locateFile: file => "./node_modules/libpag/lib/" + file
    // }).then(async PAG => {
    //   const file = require("@/assets/pag/下载引流文件.pag");
    //   const pagFile = await PAG.PAGFile.load(file);
    //   document.getElementById("pag").width = await pagFile.width();
    //   document.getElementById("pag").height = await pagFile.height();
    //   const pagView = await PAG.PAGView.init(pagFile, "#pag");
    //   pagView.setRepeatCount(0);
    //   await pagView.play();
    //   console.log(PAG);
    // });
    window.libpag.PAGInit().then(PAG => {
      // const url = "https://pag.io/file/like.pag";
      const url = require("@/assets/pag/下载引流文件.pag");

      fetch(url)
        .then(response => response.blob())
        .then(async blob => {
          const file = new window.File(
            [blob],
            url.replace(/(.*\/)*([^.]+)/i, "$2")
          );
          console.log(PAG, file);
          const pagFile = await PAG.PAGFile.load(file);
          // document.getElementById("pag").width = await pagFile.width();
          // document.getElementById("pag").height = await pagFile.height();
          const pagView = await PAG.PAGView.init(pagFile, "#pag");
          pagView.setRepeatCount(0);
          await pagView.play();
          // Do Something.
        });
    });
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
    urlPC() {
      return window.location.origin + "/shop?shopId=" + this.shopId;
    },
    urlMobile() {
      return window.location.origin + "/h5/shop?shopId=" + this.shopId;
    },
    ...mapGetters(["userInfo"]),

    menus() {
      const roots = this.$router.options.routes;
      const routes = roots.find(i => i.name === "Layout");
      const companyMenus = [
        {
          icon: require("@/assets/images/dashboard/icon/编组 12备份@2x.png"),
          title: "概览",
          menuGroup: "index",
          subItems: routes.children.filter(
            i => i.meta.menuGroup == "index" && !i.meta.hidden
          )
        },
        {
          icon: require("@/assets/images/dashboard/icon/编组 11@2x.png"),
          title: "课程管理",
          menuGroup: "course",
          subItems: routes.children.filter(
            i => i.meta.menuGroup == "course" && !i.meta.hidden
          )
        },
        {
          icon: require("@/assets/images/dashboard/icon/编组 7@2x.png"),
          title: "员工管理",
          menuGroup: "employee",
          subItems: routes.children.filter(
            i => i.meta.menuGroup == "employee" && !i.meta.hidden
          )
        }
      ];
      const organizationMenus = [
        {
          icon: require("@/assets/images/dashboard/icon/编组 33@2x.png"),
          title: "机构首页",
          menuGroup: "organizationIndex",
          subItems: routes.children.filter(
            i => i.meta.menuGroup == "organizationIndex" && !i.meta.hidden
          )
        },
        {
          icon: require("@/assets/images/dashboard/icon/编组 11@2x.png"),
          title: "主页管理",
          menuGroup: "series_course",
          subItems: routes.children.filter(
            i => i.meta.menuGroup == "series_course" && !i.meta.hidden
          )
        }
      ];

      if (this.userInfo.userRole == "ORGANIZATION") {
        return organizationMenus;
      } else {
        return companyMenus;
      }
    },
    breadcrumbs() {
      const menuGroup = this.$route.meta.menuGroup;
      const title = this.$route.meta.title;
      return [
        {
          title: this.menus.find(i => i.menuGroup == menuGroup)?.title,
          path: "/"
        },
        {
          title: title,
          path: "/"
        }
      ];
    }
  },
  created() {
    this.getData();
  },
  beforeEnter(to, from, next) {
    store.actions.getUserInfo();
    if (store.getters.userInfo.userRole == null) {
      next("/change_role");
    } else if (store.getters.userInfo.userRole == "COMPANY") {
      next("/index");
    } else if (store.getters.userInfo.userRole == "ORGANIZATION") {
      next("/organization_index");
    }
  }
};
</script>

<style lang="scss" scoped>
.logo {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    height: 45px;
  }
}
.aside {
  background: #052069;
  min-height: 100vh;
}
.header {
  background: #052069;
  padding: 0;
  nav {
    height: 90px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 40px 0px 0px 0px;
    background: #fff;

    .avator {
      display: flex;
      align-items: center;
      img {
        height: 62px;
        width: 62px;
        border-radius: 50%;
      }
    }

    .userRole {
      color: #fff;
      margin-left: 20px;
      padding: 8px 17px 8px 19px;
      box-shadow: 0px 4px 8px 0px rgba(255, 90, 60, 0.3);
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0);
      cursor: pointer;
    }
    .organizationRole {
      background: linear-gradient(180deg, #f0952c 0%, #ffb833 100%);
    }

    .companyRole {
      background: linear-gradient(180deg, #528ae3 0%, #0068ff 98%);
    }
  }
}
.dashbord-container {
  background: #f7f7f7;
}
.title {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 18px;
}
.username {
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #323232;
}
.menu-icon {
  height: 24px;
  width: 24px;
  margin-right: 20px;
}
.main {
  min-height: calc(100vh - 240px);
}
.app-download {
  width: 177px;
  height: 64px;
  // background: #fff;
  border-radius: 20px;
  position: fixed;
  bottom: 78px;
  left: 30px;
}

.changeButton {
  display: flex;

  img {
    width: 450px;
  }

  &-item {
    cursor: pointer;
  }
}
::v-deep {
  .el-submenu .el-menu-item {
    padding-left: 64px !important;
    &:hover {
      background-color: rgba($color: #2a8cf0, $alpha: 0.4) !important;
      color: #fff !important;
    }
  }
  .el-menu-item.is-active {
    background-color: rgba($color: #2a8cf0, $alpha: 0.4) !important;
  }
  .el-submenu.is-active .el-submenu__title,
  .el-submenu.is-active .el-submenu__title i {
    color: #9abbff !important;
    font-weight: bold;
  }

  .el-icon-arrow-down {
    color: #fff;
  }
}
#pcshopurl {
  margin-top: 20px;
  display: inline-block;
  width: 383px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  font-size: 14px;
  font-weight: 400;
  color: #3c3d40;
  line-height: 32px;
  padding: 0 10px;
}
</style>
