<template>
  <div class="contents">
    <div class="container" v-show="live">
      <Breadcrumb title="播放" class="breadcrumb" />

      <div class="live-box">
        <div class="player-box">
          <div class="player-title">
            <div class="broadcast-ico">
              <div
                v-if="activeVideo.liveStatus === 1"
                style="border-color: #37f; color: #37f;"
              >
                直播
              </div>
              <div
                v-else-if="activeVideo.liveStatus === 0"
                style="border-color: #f0b71d; color: #f0b71d;"
              >
                即将开播
              </div>
              <div
                v-else-if="activeVideo.liveStatus === 2"
                style="border-color: #546e96; color: #546e96;"
              >
                直播结束
              </div>
              <div
                v-else-if="activeVideo.liveStatus === 3"
                style="border-color: #35d597; color: #35d597;"
              >
                回放
              </div>
              <div
                v-else-if="$route.query.courseType === 5"
                style="border-color: #e07471; color: #e07471;"
              >
                系列课
              </div>
              <div
                v-else-if="$route.query.courseType == 'rrs'"
                style="border-color: #e07471; color: #e07471;"
              >
                系列课
              </div>
              <div
                v-else-if="$route.query.courseType == 'course'"
                style="border-color: #d6b88a; color: #d6b88a;"
              >
                官方课程
              </div>
            </div>
            <div class="broadcast-title">
              {{
                activeVideo.liveTitle ||
                  activeVideo.title ||
                  activeVideo.columnTitle
              }}
            </div>
          </div>
          <div class="player-area">
            <AliPlayer
              v-if="videoSource"
              :source="videoSource"
              :isLive="activeVideo.liveStatus == 1"
              :cover="activeVideo.liveLogoImg || activeVideo.coverUrl"
              @play="isPlaying = true"
              :pwd="pwd"
              :price="price"
              :free="this.activeCourse.free"
              :duration="duration"
              :codeUrl="qrcodeUrl"
            />
            <div v-else class="status-cover">
              <div v-if="activeVideo.liveStatus == 0" class="dead-time">
                距开播还有 {{ d }}天{{ h }}时{{ m }}分{{ s }}秒
              </div>
              <img
                src="@/assets/images/live/背景图横版ready@2x.png"
                alt=""
                v-if="
                  activeVideo.liveStatus == 0 || activeVideo.liveStatus == 1
                "
              />
              <img
                src="@/assets/images/live/背景图横版end@2x.png"
                alt=""
                v-if="activeVideo.liveStatus == 2"
              />
            </div>
            <div
              class="audio"
              v-show="
                isPlaying &&
                  activeVideo.liveMode == 1 &&
                  (activeVideo.liveStatus == 1 ||
                    activeVideo.liveStatus == 3 ||
                    activeVideo.liveStatus == 4)
              "
            >
              <img src="@/assets/images/live/audio.gif" alt="" />
              <p>语音直播中</p>
              <img src="@/assets/images/live/audio.gif" alt="" />
            </div>
          </div>
        </div>
        <div class="info-box">
          <el-tabs v-model="activeName" stretch>
            <!-- <el-tab-pane label="大家说" name="a">
              <ChatRoom />
            </el-tab-pane> -->
            <el-tab-pane label="了解我" name="a">
              <el-scrollbar style="height: 100%;">
                <VideoInfo />
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane
              v-if="
                (activeVideo.liveStatus > 4 ||
                  (activeVideo.liveStatus <= 4 &&
                    activeVideo.liveSeries != 0)) &&
                  $route.query.courseType == 'live'
              "
              label="系列课"
              name="b"
              :key="1"
            >
              <el-scrollbar style="height: 100%;">
                <VideoList />
              </el-scrollbar>
            </el-tab-pane>

            <el-tab-pane
              v-if="
                $route.query.courseType == 'rrs' ||
                  $route.query.courseType == 'ott'
              "
              label="系列课"
              name="b"
              :key="2"
            >
              <el-scrollbar style="height: 100%;">
                <KsbList />
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane
              v-if="$route.query.courseType == 'ott'"
              label="系列课"
              name="b"
              :key="2"
            >
              <el-scrollbar style="height: 100%;">
                <KsbList />
              </el-scrollbar>
            </el-tab-pane>

            <el-tab-pane
              v-if="$route.query.courseType == 'course'"
              label="系列课"
              name="b"
              :key="3"
            >
              <el-scrollbar style="height: 100%;">
                <CourseList />
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane label="资料" name="c" v-if="pdfInfo.length > 0">
              <el-scrollbar style="height: 100%;">
                <PdfList />
              </el-scrollbar>
            </el-tab-pane>
            <!-- <el-tab-pane v-if="activeVideo.liveSeries" title="系列课" name="b">
        <VideoList />
      </el-tab-pane> -->
            <!-- <el-tab-pane v-if="activeVideo.liveStatus != 1" title="系列课" name="b">
        <VideoList />
      </el-tab-pane> -->
            <!-- <el-tab-pane title="大家说" name="c">
        <ChatRoom />
      </el-tab-pane> -->
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Overlay, Field, Toast } from "vant";
import AliPlayer from "@/components/AliPlayer";
import VideoInfo from "./VideoInfo";
import Breadcrumb from "../../components/Breadcrumb";
import VideoList from "./videoList";
import KsbList from "./KsbList";
import PdfList from "./PdfList";
// import ChatRoom from "./chatRoom";
import CourseList from "./CourseList";
import { mapState, mapGetters, mapActions } from "vuex";
import QRCode from "qrcode";
// import * as API from "@/api/index";
export default {
  data() {
    return {
      // pwdpause: false,
      activeName: "a",
      livePassword: "",
      isPlaying: false,
      showPassword: false,
      d: "00",
      h: "00",
      m: "00",
      s: "00",
      timer: 0,
      qrcodeUrl: "",
      payUrl: ""
    };
  },
  components: {
    AliPlayer,
    VideoInfo,
    PdfList,
    Breadcrumb,
    VideoList,
    // ChatRoom,
    KsbList,
    CourseList
    // Overlay,
    // Field,
  },
  computed: mapState({
    ...mapGetters(["userInfo"]),
    live: state => state.media.live,
    pdfInfo: state => state.media.pdfInfo, //资料
    courseInfo: state => state.media.courseInfo,
    streamUrl: state => state.media.videoInfo.streamUrl || {},
    activeItemVideo: state => state.media.activeItemVideo,
    activeCourse: state => state.media.activeCourse,
    activeVideo: state => state.media.activeVideo, //liveEntity
    password: state => state.media.password, //直播密码
    videoSource() {
      if (this.$route.query.courseType == "ott") {
        return this.activeCourse.playUrl;
      }
      if (this.$route.query.courseType == "course") {
        return (
          this.activeCourse.streamUrl && this.activeCourse.streamUrl.playUrl
        );
      }
      if (this.$route.query.courseType == "rrs") {
        return this.activeCourse.liveUrl;
      }
      if (this.$route.query.courseType == "ott") {
        return this.activeCourse.playUrl;
      }
      return this.streamUrl.videoSource || this.streamUrl.playUrl;
    },
    //liveType 0公开/1密码
    pwd() {
      if (this.activeVideo.liveType == 0) {
        return null;
      } else {
        return this.activeVideo.livePassword;
      }
    },
    price() {
      if (this.activeVideo.isBrought === true) {
        return 0;
      } else if (this.activeVideo.discountPrice >= 0) {
        return this.activeVideo.discountPrice;
      } else {
        return this.activeVideo.price; //第三方
      }
    },
    duration() {
      if (
        this.activeVideo.isBrought == true ||
        this.price == 0 ||
        this.$route.query.courseType == "rrs"
      ) {
        return 0;
      } else {
        return this.activeCourse.duration;
      }
    }
  }),
  created() {
    const { id, courseType } = this.$route.query;
    if (!id) {
      this.Message.error("参数错误");
      return;
    }
    if (courseType == "rrs") {
      this.getRrdData(id);
    } else if (courseType == "course") {
      this.getCourseData(id);
      this.getPdf({ id, courseType });
    } else if (courseType == "ott") {
      this.getOttData(id);
      // this.getPdf({ id, courseType });
    } else {
      this.getVideo(id);
      this.getPdf({ id, courseType });
    }
  },
  mounted() {
    this.getQrCode();
    console.log("试听时长", this.duration);
    window.init = this.init;
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.loop);
  },
  watch: {
    isPlaying() {},
    "activeVideo.liveStatus"() {},
    "activeVideo.deadTime"(val) {
      if (val) {
        this.timer = setInterval(() => {
          if (val <= 0) {
            clearInterval(this.timer);
          }
          let d = parseInt(val / (24 * 3600));
          let h = parseInt((val % (24 * 3600)) / 3600);
          let m = parseInt((val % 3600) / 60);
          let s = parseInt(val % 60);
          this.d = d > 9 ? d : `0${d}`;
          this.h = h > 9 ? h : `0${h}`;
          this.m = m > 9 ? m : `0${m}`;
          this.s = s > 9 ? s : `0${s}`;
          val -= 1;
        }, 1000);
      }
    }
    // status(val) {
    //   if (val == 2) {
    //     const { id } = this.$route.query;
    //     this.loop = setInterval(() => {
    //       this.$store.dispatch("getVideo", id);
    //     });
    //   } else {
    //     clearInterval(this.loop);
    //   }
    // }
    // "activeVideo.liveType"(val) {
    //   // this.pwd = this.activeVideo.livePassword;
    //   if (val == 1) {
    //     // this.pwd = this.activeVideo.livePassword;
    //     // this.livePassword = this.activeVideo.livePassword;
    //     // this.showPassword = true;
    //     // this.pwdpause = true;
    //   } else {
    //     // this.pwd = "";
    //     // this.showPassword = false;
    //     // this.pwdpause = false;
    //   }
    // }
  },

  methods: {
    ...mapActions("media", [
      "getVideo",
      "getPdf",
      "getRrdData",
      "getCourseData",
      "getPwd",
      "getOttData"
    ]),
    init() {
      this.loop = setInterval(this.getPassword, 60000);
    },
    getPassword() {
      const { id } = this.$route.query;
      this.getPwd(id);
    },
    getQrCode() {
      this.payUrl =
        process.env.VUE_APP_HTTP +
        "/pay?id=" +
        this.$route.query.id +
        "&token=" +
        this.userInfo.token +
        "&courseType=" +
        this.$route.query.courseType;
      console.log("支付地址", this.payUrl);
      if (this.payUrl) {
        QRCode.toDataURL(this.payUrl).then(url => {
          this.qrcodeUrl = url;
        });
      }
    }
    // isMobile() {
    //   let flag = navigator.userAgent.match(
    //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    //   );
    //   console.log(flag);
    //   return flag;
    // }
    // checkPwd() {
    //   if (this.pwd.length == 4) {
    //     if (this.pwd == this.livePassword) {
    //       this.pwd = "";
    //       this.showPassword = false;
    //       this.pwdpause = false;
    //     } else {
    //       // Toast.fail("密码错误");
    //     }
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.contents {
  background: #ccc url(../../assets/images/background/bg3.png) no-repeat 0 /
    cover;
  height: 100%;
  min-height: calc(100vh - 164px);
  width: 100%;
  z-index: -10;
  zoom: 1;
}

::v-deep .el-tabs__header {
  background-color: white;
  margin: 0 0 15px;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 999;
}

.broadcast-ico div {
  border: 1px solid;
  border-radius: 8px;
  float: left;
  font: 600 15px PingFang SC;
  line-height: 26px;
  margin-right: 30px;
  padding: 1px 8px;
  text-align: center;
}

.breadcrumb {
  padding: 25px 0;
}

.live-box {
  display: flex;
  height: 656px;
  justify-content: space-between;
  padding: 0 0 50px;
}

.player-box {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(202, 212, 225, 0.16);
  overflow: hidden;
  width: 1050px;
}

.info-box {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(202, 212, 225, 0.16);
  overflow: auto;
  width: 311px;
}

.player-title {
  background-color: #fff;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 600;
  height: 61px;
  line-height: 29px;
  padding: 16px 21px;
  position: relative;
}

.broadcast-title {
  color: #1f2e4d;
  float: left;
  font: 600 20px PingFang SC;
  line-height: 29px;
}

.check-pwd {
  // margin: 100px auto;
  // width: 50%;
}

.status-cover {
  height: fit-content;
  position: relative;

  img {
    width: 102%;
  }

  .dead-time {
    color: #fff;
    font-size: 16px;
    position: absolute;
    text-align: center;
    top: 10px;
    width: 100%;
  }
}

.player-area {
  position: relative;
}

.audio {
  align-items: center;
  color: #3d7afd;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  // transform: translateZ(0);

  img {
    height: 30px;
  }
}

.player-qrcode {
  position: absolute;
  right: 60%;
  top: 30%;
  z-index: 1111;
}
</style>
