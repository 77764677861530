<template>
  <div class="box">
    <div
      class="videoitem"
      v-for="(item, index) in list"
      :key="index"
      @click="changeVideo(item)"
    >
      <span>
        <img
          v-if="activeCourse.id == item.id"
          class="player-button"
          src="@/assets/images/live/activebutton.png"
          alt=""
        />
        <img
          v-else
          class="player-button"
          src="@/assets/images/live/listplayer.png"
          alt=""
        />
      </span>
      <div class="info">
        <div class="title">{{ item.title }}</div>
        <!-- <div class="sub">
          时长：{{ item.timelength | formatDuration }}
        </div> -->
      </div>
      <Duration
        :duration="item.duration"
        :isBrought="activeVideo.isBrought"
        :free="item.free"
      />
    </div>
    <!-- <div class="bottom-img">
      <img src="@/assets/images/live/be.png" alt="" srcset="" />
    </div> -->
  </div>
</template>

<script>
import Duration from "@/components/DurationStatus";
import { mapState } from "vuex";
export default {
  components: {
    Duration
  },
  computed: mapState({
    // loop: state => state.media.loop,
    activeVideo: state => state.media.activeVideo,
    activeCourse: state => state.media.activeCourse,
    courseInfo: state => state.media.courseInfo,
    list() {
      return this.activeVideo.chaptersList || [];
    }
  }),
  methods: {
    changeVideo(item) {
      this.$store.commit("media/SET_ACTIVE_COURSE", item);
      // if (this.activeItemVideo.id != item.id) {
      //   // this.$router.replace({ name: "Media", query: { id: item.id } });
      //   // location.reload();
      //   clearInterval(this.loop);

      //   // const { id } = this.$route.query;
      //   // if (!id) {
      //   //   this.Message.error("参数错误");
      //   //   return;
      //   // }
      //   this.$store.commit("SET_ACTIVE_THIRD", item);
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  padding: 10px;
}

.videoitem {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  margin: 0 -10px;
  padding: 10px;

  &:hover {
    background: #fff;
  }

  .player-button {
    height: 23px;
  }

  .info {
    align-items: center;
    display: flex;
    padding: 0 15px;
  }

  .title {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
  }

  .sub {
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin-top: 10px;
  }
}

.bottom-img {
  margin-top: 20px;
  text-align: center;

  img {
    width: 137px;
  }
}
</style>
