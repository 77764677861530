<template>
  <div class="layout" :style="`zoom:${screenWidthScale}`">
    <component :is="header" />
    <router-view />
    <Foot />
  </div>
</template>
<script>
import Head from "./Head";
import ShopHead from "./ShopHead";
import Foot from "./Foot";
export default {
  data() {
    return {
      screenWidthScale: 0,
      shop: false
    };
  },
  computed: {
    header() {
      const header = this.$route.meta.header;
      return header || "Head";
    }
  },
  components: {
    Head,
    Foot,
    ShopHead
  },
  created() {
    let screenWidth = document.body.clientWidth;
    if (screenWidth < 1500) {
      this.screenWidthScale = 0.8;
    }
  }
};
</script>
<style lang="scss" scoped>
.layout {
  // background-color: #f7f9fc;
  background: var(--home-background) no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.container {
  // background: #f7f9fc;
  min-height: calc(100vh - 160px);
}
</style>
