import payHtml from "./index.html";
import "./index.scss";
// import QRCode from "qrcode";

function parseDom(html) {
  let ele = document.createElement("div");
  ele.innerHTML = html;
  return ele.childNodes[0];
}

export default class PlayPwdComponent {
  /**
   * @constructor
   * @param {Array playlist 播放列表数组}
   */
  constructor(price, free, codeUrl) {
    this.payHtml = parseDom(payHtml);
    this.price = price;
    this.free = free;
    this.codeUrl = codeUrl;
  }

  createEl(el) {
    el.appendChild(this.payHtml);
  }

  ready(player) {
    player._el.querySelector(".pay-qrcode-img").src = this.codeUrl;
    if (this.price == 0) {
      this.payHtml.style.display = "none";
      // player.play();
    } else if (this.free === false) {
      // player._el.querySelector(".pay-qrcode-img").src = this.codeUrl;
      this.payHtml.style.display = "flex";

      // player.pause();
    } else {
      this.payHtml.style.display = "none";
      // player.play();
    }
  }

  // 播放出错
  error(player) {
    // alert("player" + player);
    if (this.price !== 0) {
      console.log("已购买或者课程免费");
      player._el.querySelector(".pay-qrcode-img").src = this.codeUrl;
      this.payHtml.style.display = "flex";
    }
  }
}
