<template>
  <div class="video">
    <div
      class="video-cover"
      @click="openVideo"
      :style="{
        backgroundImage:
          'url(' +
          ((data.aPICourse && data.aPICourse.coverURL) ||
            (data.aPILive && data.aPILive.liveImg) ||
            (data.rrdColumn && data.rrdColumn.coverURL)) +
          ')'
      }"
    >
      <div class="video-cover-livestate" v-if="data.aPILive">
        <LiveStatus :liveStatus="data.aPILive.liveStatus" />
      </div>
      <div class="video-cover-box">
        <div class="video-cover-teacher">
          <span>{{
            (data.aPICourse && data.aPICourse.teacher) ||
              (data.aPILive && data.aPILive.liveLecturer) ||
              (data.rrdColumn && data.rrdColumn.rrdTeachers[0].teacherName)
          }}</span>
        </div>
        <div class="video-cover-pv">
          <div class="video-cover-pv-icon">
            <img src="@/assets/images/fire.png" alt="" />
          </div>
          <div class="video-cover-pv-number">
            <span>{{
              (data.aPICourse && data.aPICourse.pv + " ") ||
                (data.aPILive &&
                  (data.aPILive.livePlayCountSetted > 0
                    ? data.aPILive.livePlayCountSetted
                    : data.aPILive.livePlayUv)) ||
                (data.rrdColumn && data.rrdColumn.pv)
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="video-bg">
      <div class="video-bg-box-left">
        <div class="video-bg-title" @click="openVideo">
          <span>{{ data.subject }}</span>
        </div>
        <div class="video-bg-box">
          <div class="video-bg-time">
            <span v-if="data.endLong > 0"
              >有效期至{{
                (data.endLong + data.createTime) | formatTime("YYYY-MM-DD")
              }}</span
            >
            <span v-if="data.endLong == 0">永久有效</span>
          </div>
          <div class="video-bg-price">&yen;{{ data.amount }}</div>
        </div>
      </div>

      <div class="video-bg-box-right">
        <img src="@/assets/images/已支付@2x.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import LiveStatus from "@/components/LiveStatus";

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    LiveStatus
  },
  data() {
    return {};
  },
  methods: {
    openVideo() {
      // window.open("https://test.ailive.cloud/pc/#/home?id=" + this.data.id);

      const { apiCourseID, apiLiveID, rrdColumnID, courseType } = this.data;
      this.$router.push({
        name: "Media",
        query: {
          id: apiCourseID || apiLiveID || rrdColumnID,
          courseType: courseType,
          live_id: 0
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.video {
  display: flex;
  flex-direction: column;
  width: 332px;
}

.video-cover {
  background: #d8d8d8;
  background-position: center;
  background-size: contain;
  background-size: cover;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 186px;
  justify-content: space-between;
  justify-content: flex-end;
  overflow: hidden;
}

.video-cover-livestate {
  display: flex;
  justify-content: flex-end;
  margin: 15px 10px 50px 0;
}

.video-cover-box {
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%);
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 0 10px 6px 15px;
}

.video-cover-teacher {
  color: #fff;
  font-size: 14px;
  max-width: 166px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.video-cover-pv {
  align-items: flex-end;
  display: flex;
}

.video-cover-pv-icon {
  display: flex;
  margin-bottom: 2px;
}

.video-cover-pv-icon img {
  width: 14px;
}

.video-cover-pv-number {
  color: #fff;
  font-size: 14px;
  // font-weight: 500;
}

.video-cover-type {
  background: #e07471;
  border-radius: 17px;
  display: flex;
}

.video-cover-type-item {
  display: flex;
  padding: 1px 9px;
}

.video-cover-type-item span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  max-width: 323px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.video-bg {
  // background: #fff;
  border-radius: 0 0 10px 10px;
  display: flex;
  // box-shadow: 0 1px 20px 0 rgba(143, 150, 163, 0.2);
  flex: 1;
  justify-content: space-between;
  // padding: 0 16px 14px;
}

.video-bg-title {
  color: #8f9bb3;
  display: flex;
  font-size: 17px;
  line-height: 25px;

  span {
    cursor: pointer;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.video-bg-box {
  display: flex;
  // justify-content: space-between;
  width: 265px;
}

.video-bg-box-left {
  padding-top: 16px;
}

.video-bg-box-right {
  padding-top: 4px;
}

.video-bg-box-right img {
  width: 75px;
}

.video-bg-time {
  color: #8f9bb3;
  display: flex;
  font-size: 15px;
  line-height: 21px;
  margin-top: 8px;
}

.video-bg-price {
  align-items: flex-end;
  color: #ed5656;
  display: flex;
  font-size: 17px;
  font-weight: bold;
  line-height: 17px;
  margin-bottom: 2px;
  opacity: 1;
  padding-left: 10px;
}

.large {
  height: 507px;
  width: 665px;

  .video-cover {
    border-radius: 5px 5px 0 0;
    box-sizing: border-box;
    height: 372px;
  }

  .video-cover-box {
    padding-bottom: 16px;
  }

  .video-cover-type-item {
    display: flex;
    padding: 7px 15px;
  }

  .video-cover-livestate {
    margin: 15px 20px 0 0;
  }

  .video-cover-box {
    padding: 0 20px 15px;
  }

  .video-cover-teacher,
  .video-cover-pv-number {
    font-size: 18px;
  }

  .video-cover-pv-icon img {
    width: 22px;
  }

  .video-bg-title {
    color: #1f2e4d;
    font-size: 18px;
    line-height: 26px;
    opacity: 1;
    padding-top: 30px;
  }

  .video-bg-time {
    color: #8f9bb3;
    font-size: 16px;
    line-height: 22px;
    opacity: 1;
    padding-top: 10px;
  }
}

.usercenter {
  height: 295px;
  margin-bottom: 33px;
  width: 331px;
}
</style>
