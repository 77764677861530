<template>
  <div class="player-container">
    <div id="player_mp4"></div>
  </div>
</template>

<script>
import PwdConfirm from "./playerComponents/PwdConfirm/index";
import PlayStatusComponent from "./playerComponents/PlayStatusComponent/index";
import RateComponent from "./playerComponents/RateComponent/index";
import PayComponent from "./playerComponents/PayComponent/index";
import PreviewVodComponent from "./playerComponents/PreviewVodComponent/index";
export default {
  components: {},
  props: {
    cover: {
      type: String,
      default: ""
    },
    isLive: {
      type: Boolean,
      default: false
    },
    pwd: {
      type: String,
      default: ""
    },
    source: {
      type: String,
      default: ""
    },
    price: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 0
    },
    free: {
      type: Boolean,
      default: false
    },
    codeUrl: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      vcover: require("@/assets/images/live/背景图横版ready@2x.png"),
      showError: false,
      pwdConfirm: false,
      mp4Player: null
    };
  },
  watch: {
    pwd(val) {
      this.$router.go(0);
      this.mp4Player.pause();
      if (val) {
        this.mp4Player.pause();
      } else {
        this.mp4Player.play();
      }
    },
    source() {
      // this.mp4Player.loadByUrl(val);
      if (this.mp4Player) {
        this.mp4Player.dispose();
        this.initPlayer();
      }
    }
  },

  destroyed() {
    this.$store.commit("media/RESET_VIDEO");
  },
  mounted() {
    this.initPlayer();
  },
  methods: {
    initPlayer() {
      var _this = this;
      console.log("this.source", this.source);
      this.mp4Player = new window.Aliplayer(
        {
          id: "player_mp4",
          source: this.source,
          height: "100%",
          autoplay: true,
          isLive: this.isLive,
          playsinline: true,
          preload: true,
          showBuffer: true,
          controlBarVisibility: "hover",
          useH5Prism: false,
          extraInfo: {
            crossOrigin: "anonymous",
            liveRetry: 1
          },
          // cover: this.cover, //播放器的背景图
          cover: _this.vcover, //播放器的背景图
          skinLayout: [
            { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
            {
              name: "H5Loading",
              align: "cc"
            },
            { name: "errorDisplay", align: "tlabs", x: 0, y: 0 },
            { name: "infoDisplay" },
            { name: "tooltip", align: "blabs", x: 0, y: 56 },
            { name: "thumbnail" },
            {
              name: "controlBar",
              align: "blabs",
              x: 0,
              y: 0,
              children: [
                { name: "progress", align: "blabs", x: 0, y: 44 },
                { name: "playButton", align: "tl", x: 15, y: 12 },
                { name: "timeDisplay", align: "tl", x: 10, y: 7 },
                { name: "fullScreenButton", align: "tr", x: 10, y: 12 },
                // { name: 'subtitle', align: 'tr', x: 15, y: 12 },
                // { name: 'setting', align: 'tr', x: 15, y: 12 },
                { name: "volume", align: "tr", x: 5, y: 10 }
              ]
            }
          ],
          components: [
            {
              type: PwdConfirm,
              args: [() => this.pwd]
            },
            {
              type: PlayStatusComponent,
              args: [() => this.price, this.free, this.duration, this.pwd]
            },
            {
              type: RateComponent,
              args: [this.isLive]
            },
            {
              type: PayComponent,
              args: [this.price, this.free, this.codeUrl]
            },
            {
              type: PreviewVodComponent,
              args: [
                this.price,
                this.duration / 1000,
                `<div><div class="pay-text">该课程为付费课程，购买后才可观看</div><div class="pay-qrcode"><img src="${this.codeUrl}" /></div><div class="pay-qrtext">扫一扫&nbsp;&nbsp;轻松购买</div></div>`,
                `<div><span>，购买后课观看完整视频</span></div>`
              ]
            }
          ]
        },
        function(player) {
          // player.on("ready", () => {});
          player.on("error", () => {
            // _this.isLiveEnd = true;
            // _this.bigBg = true;
            // _this.audioShow = false;
            // _this.$emit("error");
            _this.showError = true;
            // Toast.fail("播放出错了，请刷新重试");
            // Toast.fail("播放中断，请稍候~");
          });
          // player.on("canplay", () => {});
          // player.on("showBar", () => {});
          // player.on("pause", () => {});
          // player.on("requestFullScreen", () => {
          // Toast({
          //   message: "语音直播中",
          //   icon: require("../../assets/audio.gif")
          // });
          // });
          // player.on("cancelFullScreen", () => {});
          // player.on("hideBar", () => {});
          player.on("ready", () => {
            _this.showError = false;
          });
          player.on("play", () => {
            _this.showError = false;
          });
        }
      );
      this.mp4Player.on("ready", () => {
        _this.$emit("play");
        _this.showError = false;
        this.mp4Player.on("play", () => {
          _this.showError = false;
        });
        this.mp4Player.on("error", err => {
          console.error(err);
          _this.showError = false;
          this.vcover = require("@/assets/images/live/背景图横版error@2x.png");
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// :v-deep {
//   .prism-player {
//     .prism-big-play-btn {
//       left: 50% !important;
//       top: 50% !important;
//       transform: translate(-50%, -50%);
//     }
//   }
// }

.player-container {
  background: #000;
  height: 595px;
}

.passwd {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  z-index: 9999;
}
</style>
<style>
.prism-player .prism-big-play-btn {
  left: 100px !important;
  /* left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%); */
  transform: scale(1.5);
}
/* 
.prism-player .prism-info-display {
  width: auto;
}

.prism-player .prism-cover {
  background-size: 100% 100%;
} */
</style>
