var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video"},[_c('div',{staticClass:"video-cover",style:({
      backgroundImage:
        'url(' +
        ((_vm.data.aPICourse && _vm.data.aPICourse.coverURL) ||
          (_vm.data.aPILive && _vm.data.aPILive.liveImg) ||
          (_vm.data.rrdColumn && _vm.data.rrdColumn.coverURL)) +
        ')'
    }),on:{"click":_vm.openVideo}},[(_vm.data.aPILive)?_c('div',{staticClass:"video-cover-livestate"},[_c('LiveStatus',{attrs:{"liveStatus":_vm.data.aPILive.liveStatus}})],1):_vm._e(),_c('div',{staticClass:"video-cover-box"},[_c('div',{staticClass:"video-cover-teacher"},[_c('span',[_vm._v(_vm._s((_vm.data.aPICourse && _vm.data.aPICourse.teacher) || (_vm.data.aPILive && _vm.data.aPILive.liveLecturer) || (_vm.data.rrdColumn && _vm.data.rrdColumn.rrdTeachers[0].teacherName)))])]),_c('div',{staticClass:"video-cover-pv"},[_vm._m(0),_c('div',{staticClass:"video-cover-pv-number"},[_c('span',[_vm._v(_vm._s((_vm.data.aPICourse && _vm.data.aPICourse.pv + " ") || (_vm.data.aPILive && (_vm.data.aPILive.livePlayCountSetted > 0 ? _vm.data.aPILive.livePlayCountSetted : _vm.data.aPILive.livePlayUv)) || (_vm.data.rrdColumn && _vm.data.rrdColumn.pv)))])])])])]),_c('div',{staticClass:"video-bg"},[_c('div',{staticClass:"video-bg-box-left"},[_c('div',{staticClass:"video-bg-title",on:{"click":_vm.openVideo}},[_c('span',[_vm._v(_vm._s(_vm.data.subject))])]),_c('div',{staticClass:"video-bg-box"},[_c('div',{staticClass:"video-bg-time"},[(_vm.data.endLong > 0)?_c('span',[_vm._v("有效期至"+_vm._s(_vm._f("formatTime")((_vm.data.endLong + _vm.data.createTime),"YYYY-MM-DD")))]):_vm._e(),(_vm.data.endLong == 0)?_c('span',[_vm._v("永久有效")]):_vm._e()]),_c('div',{staticClass:"video-bg-price"},[_vm._v("¥"+_vm._s(_vm.data.amount))])])]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-cover-pv-icon"},[_c('img',{attrs:{"src":require("@/assets/images/fire.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-bg-box-right"},[_c('img',{attrs:{"src":require("@/assets/images/已支付@2x.png"),"alt":""}})])}]

export { render, staticRenderFns }