<template>
  <div
    v-if="
      $store.state.home.firstList.courseList !== undefined &&
        $store.state.home.firstList.courseList.length > 0
    "
  >
    <div class="home-title">
      <span>{{ headline }}</span>
      <!-- <span
        class="home-more"
        @click="$router.push({ name: 'More', params: { tab: 1 } })"
      >
        MORE
        <img :src="require('@/assets/images/路径@2x.png')" />
      </span> -->
    </div>
    <div class="home-course">
      <VideoCard
        theme="large"
        :data="courseList[0] || {}"
        nextRouteName="ShopMedia"
      />
      <div class="right">
        <!-- <swiper ref="swiper" :options="swiperOptions" style="height: 500px;">
          <swiper-slide :key="index" v-for="(banners, index) in swiperList">
            <VideoCard :data="banners[0]" />
            {{ index }}
          </swiper-slide>
        </swiper> -->
        <VideoCard
          theme="course"
          v-for="(item, index) in list"
          :data="item"
          :key="index"
          nextRouteName="ShopMedia"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VideoCard from "../../components/VideoCard";

export default {
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    };
  },
  components: {
    VideoCard
  },
  computed: {
    courseList() {
      return this.$store.state.home.firstList.courseList;
    },
    swiperList() {
      return (
        this.arrTrans(this.courseList.slice(1, this.courseList.length)) || []
      );
    },
    list() {
      return (this.$store.state.home.firstList.courseList || []).slice(1, 5);
    },
    headline() {
      if (this.$store.state.home.firstList.typeValue == 1) {
        return "直播课";
      } else if (this.$store.state.home.firstList.typeValue == 2) {
        return "系列课";
      } else {
        return "录播课";
      }
    }
  },
  methods: {
    arrTrans(baseArray) {
      let len = baseArray.length;
      let n = 4; //每行显示4个
      let lineNum = len % n === 0 ? len / n : Math.floor(len / n + 1);
      let res = [];
      for (let i = 0; i < lineNum; i++) {
        let temp = baseArray.slice(i * n, i * n + n);
        res.push(temp);
      }
      return res;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/modules/home";

.home-course {
  display: flex;

  .right {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 42px;
    width: 677px;
  }
}
</style>
