<template>
  <div class="upload-box">
    <img src="../../assets/images/bg_top.png" alt="" style="width: 100%;" />
    <el-card shadow="never">
      <div slot="header" class="title">
        <div>人单合一 • 视频上传</div>
      </div>
      <div class="form" v-if="!uploadSuccessShow">
        <!--  -->
        <el-form ref="form" :model="form" :rules="rules" label-width="150px">
          <el-form-item label="标题名称" required prop="title">
            <el-input
              v-model="form.title"
              maxlength="33"
              placeholder="标题名称"
              :show-word-limit="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名" required prop="userName">
            <el-input
              v-model="form.userName"
              maxlength="11"
              placeholder="姓名"
              :show-word-limit="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="头像/logo" required prop="headImg">
            <ImageField v-model="form.headImg">
              <span class="tips">仅支持图片格式，推荐尺寸建议200*200</span>
            </ImageField>
          </el-form-item>
          <el-form-item label="课程简介" required prop="desc">
            <el-input
              type="textarea"
              v-model="form.desc"
              placeholder="课程简介"
              maxlength="350"
              :show-word-limit="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属系列">
            <el-select
              style="width: 500px;"
              v-model="form.series"
              placeholder="请选择"
            >
              <el-option
                v-for="item in seriesOptions"
                :key="item.id"
                :label="item.liveTitle"
                :value="item.id"
                :disabled="item.id == 0"
              >
                <div v-if="item.id == 0" @click="addSeries" class="add-btn">
                  {{ item.liveTitle }}
                </div>
              </el-option>
            </el-select>

            <span style="padding-left: 10px;"
              >（不选择所属系列课则默认为单节录播课）</span
            >
          </el-form-item>
          <el-form-item label="课程封面" required prop="coverImg">
            <ImageField v-model="form.coverImg">
              <span class="tips">仅支持图片格式，推荐尺寸建议388*246</span>
            </ImageField>
          </el-form-item>
          <el-form-item label="上传视频" required prop="videoId">
            <div class="clearfix videoUpload">
              <div class="clearfix">
                <input
                  id="videoUpload"
                  ref="videoUpload"
                  name="videoUpload"
                  type="file"
                  accept="video/mp4"
                  @change="videoChange($event)"
                />
                <p class="fl tips3">
                  *仅支持仅支持mp4、m3u8、flv格式视频文件，建议视频时间在90分钟内
                </p>
              </div>

              <p class="status">
                上传状态: <span>{{ statusText }}</span>
              </p>
              <el-button :disabled="uploadDisabled" @click="authUpload()">
                开始上传
              </el-button>
              <el-button :disabled="pauseDisabled" @click="pauseUpload()">
                暂停
              </el-button>
              <el-button :disabled="resumeDisabled" @click="resumeUpload()">
                恢复上传 </el-button
              ><br />
            </div>
            <div class="progress clearfix">
              <div class="fl auth-progress">
                <p :style="{ width: authProgress + '%' }" />
              </div>
              <p class="fl percent">
                {{ authProgress + "%" }}
              </p>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button @click="save" type="primary">立即创建</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="over" v-else-if="uploadSuccessShow">
        <h3>人单合一——视频上传</h3>
        <img src="../../assets/images/success.png" alt="" />
        <div>上传成功</div>
        <p>可到“人单合一”APP中登陆对应账号查看视频审核状态，进行分享等操作</p>
        <p>如有产品操作问题，可联系客服微信：rendanheyi2021</p>
        <p>如有问题，可联系客服电话：17501069937</p>
      </div>
    </el-card>
    <el-dialog
      title="新建系列课"
      :visible.sync="addSeriesInfoVisible"
      width="900px"
      center
      class="add-visible"
      :close-on-click-modal="false"
      @close="resetForm"
    >
      <div class="form">
        <el-form :model="add" ref="add" :rules="addRules" label-width="150px">
          <el-form-item required label="系列课名称" prop="liveTitle">
            <el-input
              v-model="add.liveTitle"
              maxlength="30"
              placeholder="课程名称"
              :show-word-limit="true"
            ></el-input>
          </el-form-item>
          <el-form-item required label="主办人/主讲人" prop="liveLecturer">
            <el-input
              v-model="add.liveLecturer"
              autocomplete="off"
              maxlength="11"
              placeholder="主办人/主讲人"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item required label="课程简介" prop="liveDescription">
            <el-input
              type="textarea"
              v-model="add.liveDescription"
              autocomplete="off"
              maxlength="350"
              placeholder="课程简介"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item required label="上传封面" class="img" prop="liveImg">
            <ImageField v-model="add.liveImg">
              <span class="tips">仅支持图片格式，推荐尺寸建议388*246</span>
            </ImageField>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addSubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import OSS from "ali-oss";
import ImageField from "@/components/ImageField";
import { mapGetters } from "vuex";
import {
  uploadVideo,
  getSeries,
  createUploadVideo,
  refreshUploadVideo,
  createSeries
} from "@/api/index.js";

export default {
  name: "Upload",
  components: { ImageField },
  computed: {
    ...mapGetters(["userInfo"]),
    headImg() {
      return (
        this.userInfo.headImg || require("@/assets/images/touxiang@2x.png")
      );
    }
  },
  data() {
    return {
      form: {
        title: "",
        userName: "",
        headImg: "",
        desc: "",
        series: "",
        coverImg:
          "http://haierzzfb-media.oss-cn-beijing.aliyuncs.com/live/1628649336803_%E5%B0%81%E9%9D%A2.png",
        videoId: ""
      },
      rules: {
        title: [{ required: true, message: "请输入标题名称", trigger: "blur" }],
        userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        headImg: [
          { required: true, message: "请输上传头像/logo", trigger: "blur" }
        ],
        desc: [{ required: true, message: "请输入课程简介", trigger: "blur" }],
        coverImg: [
          { required: true, message: "请上传课程封面", trigger: "blur" }
        ],
        videoId: [{ required: true, message: "请上传视频", trigger: "blur" }]
      },
      add: {
        liveTitle: "",
        liveLecturer: "",
        liveDescription: "",
        liveLogoImg: "",
        liveImg:
          "http://haierzzfb-media.oss-cn-beijing.aliyuncs.com/live/1628649336803_%E5%B0%81%E9%9D%A2.png"
      },
      addRules: {
        liveTitle: [
          { required: true, message: "请输入系列课名称", trigger: "blur" }
        ],
        liveLecturer: [
          { required: true, message: "请输入主办人/主讲人", trigger: "blur" }
        ],
        liveDescription: [
          { required: true, message: "请输入课程简介", trigger: "blur" }
        ],
        liveImg: [{ required: true, message: "请上传封面", trigger: "blur" }]
      },
      uploadSuccessShow: false,
      // 发送短信相关
      descript: "",
      // 上传视频字段
      timeout: "",
      partSize: "",
      parallel: "",
      retryCount: "",
      retryDuration: "",
      region: "oss-cn-beijing",
      userId: "1303984639806000",
      file: null,
      authProgress: 0,
      uploadDisabled: true,
      resumeDisabled: true,
      pauseDisabled: true,
      uploader: null,
      statusText: "",
      imgClient: {},
      uploadInfo: {},
      successTimeOut: 3,
      localPreview: "",
      temporaryVideoId: "", //临时videoID
      player: {},
      isSave: false,
      seriesOptions: [],
      addSeriesInfoVisible: false
    };
  },
  created() {
    this.getSeries();
  },
  mounted() {
    this.form.headImg = this.headImg;
  },
  methods: {
    addSeries() {
      this.addSeriesInfoVisible = true;
      this.$nextTick(() => {
        this.$refs["add"].resetFields();
      });
    },
    addSubmit() {
      this.$refs["add"].validate(valid => {
        if (valid) {
          createSeries({
            liveTitle: this.add.liveTitle,
            liveLecturer: this.add.liveLecturer,
            liveDescription: this.add.liveDescription,
            liveLogoImg: this.add.liveLogoImg,
            liveImg: this.add.liveImg
          }).then(() => {
            this.$message.success("系列课创建成功", 1200);
            this.resetForm();
            this.getSeries();
          });
        }
      });
    },
    getSeries() {
      getSeries().then(data => {
        this.seriesOptions = data;
        this.seriesOptions.push({
          id: 0,
          liveTitle: "+ 添加系列课"
        });
      });
    },
    resetForm() {
      this.addSeriesInfoVisible = false;
      this.add.liveTitle = "";
      this.add.liveLecturer = "";
      this.add.liveDescription = "";
      this.add.liveLogoImg = "";
      this.add.liveImg = "";
    },
    save() {
      if (this.isSave) {
        return false;
      }

      this.$refs["form"].validate(valid => {
        if (valid) {
          // 保存视频信息
          if (!this.uploadInfo.videoId) {
            this.$message.warning("请上传视频");
            return;
          }
          uploadVideo({
            liveLogoImg: this.form.headImg,
            liveDescription: this.form.desc,
            liveTitle: this.form.title,
            liveLecturer: this.form.userName,
            videoId: this.form.videoId,
            liveImg: this.form.coverImg,
            liveSeries: this.form.series
          }).then(() => {
            this.uploadSuccessShow = true;
            this.isSave = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    videoChange(e) {
      this.file = e.target.files[0];
      let strArr = this.file.name.split(".");
      console.log(1111, strArr[strArr.length - 1]);
      if (!this.file) {
        this.$message.error("请先选择需要上传的文件!");
        return;
      }
      if (
        strArr[strArr.length - 1] != "mp4" &&
        strArr[strArr.length - 1] != "m3u8" &&
        strArr[strArr.length - 1] != "flv" &&
        strArr[strArr.length - 1] != "MP4"
      ) {
        this.$message.error("请上传正确的文件格式!");
        return;
      }

      var userData = '{"Vod":{}}';
      if (this.uploader) {
        this.uploader.stopUpload();
        this.authProgress = 0;
        this.statusText = "";
      }
      this.uploader = this.createUploader();
      console.log(userData);
      this.uploader.addFile(this.file, null, null, null, userData);
      this.uploadDisabled = false;
      this.pauseDisabled = true;
      this.resumeDisabled = true;
    },
    // 开始上传
    authUpload() {
      if (!this.form.title) {
        this.$message.error("请输入标题", 1200);
        return false;
      }
      // 然后调用 startUpload 方法, 开始上传
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.uploadDisabled = true;
        this.pauseDisabled = false;
      }
    },
    // 暂停上传
    pauseUpload() {
      if (this.uploader !== null) {
        this.uploader.stopUpload();
        this.resumeDisabled = false;
        this.pauseDisabled = true;
      }
    },
    // 恢复上传
    resumeUpload() {
      if (!this.form.title) {
        this.$message.error("请输入标题", 1200);
        return false;
      }
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.resumeDisabled = true;
        this.pauseDisabled = false;
      }
    },
    createUploader() {
      let self = this;
      let uploader = new window.AliyunUpload.Vod({
        timeout: self.timeout || 60000,
        partSize: self.partSize || 1048576,
        parallel: self.parallel || 5,
        retryCount: self.retryCount || 3,
        retryDuration: self.retryDuration || 2,
        region: self.region,
        userId: self.userId,
        // 添加文件成功
        addFileSuccess: function(uploadInfo) {
          self.uploadDisabled = false;
          self.resumeDisabled = false;
          self.statusText = "添加文件成功, 等待上传...";
          console.log("addFileSuccess: " + uploadInfo.file.name);
        },
        // 开始上传
        onUploadstarted: function(uploadInfo) {
          console.log("开始上传", uploadInfo);
          // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
          // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
          // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
          // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
          // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
          // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
          if (!uploadInfo.videoId) {
            createUploadVideo({
              title: self.form.title,
              fileName: uploadInfo.file.name
            }).then(data => {
              let uploadAuth = data.uploadAuth;
              let uploadAddress = data.uploadAddress;
              let videoId = data.videoId;
              uploader.setUploadAuthAndAddress(
                uploadInfo,
                uploadAuth,
                uploadAddress,
                videoId
              );
            });
            self.statusText = "文件开始上传...";
            console.log(
              "1111onUploadStarted:" +
                uploadInfo.file.name +
                ", endpoint:" +
                uploadInfo.endpoint +
                ", bucket:" +
                uploadInfo.bucket +
                ", object:" +
                uploadInfo.object
            );
          } else {
            // 如果videoId有值，根据videoId刷新上传凭证
            // https://help.aliyun.com/document_detail/55408.html?spm=a2c4g.11186623.6.630.BoYYcY
            refreshUploadVideo(uploadInfo.videoId).then(data => {
              let uploadAuth = data.uploadAuth;
              let uploadAddress = data.uploadAddress;
              let videoId = data.videoId;
              uploader.setUploadAuthAndAddress(
                uploadInfo,
                uploadAuth,
                uploadAddress,
                videoId
              );
            });
          }
        },
        // 文件上传成功
        onUploadSucceed: function(uploadInfo) {
          self.statusText = "文件上传完毕";
          self.uploadInfo = uploadInfo;
          self.form.videoId = uploadInfo.videoId;
        },
        // 文件上传失败
        onUploadFailed: function() {
          self.statusText = "文件上传失败!";
        },
        // 取消文件上传
        onUploadCanceled: function() {
          self.statusText = "文件已暂停上传";
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: function(uploadInfo, totalSize, progress) {
          let progressPercent = Math.ceil(progress * 100);
          self.authProgress = progressPercent;
          self.statusText = "文件上传中...";
        },
        // 上传凭证超时
        onUploadTokenExpired: function(uploadInfo) {
          // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
          // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
          // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
          refreshUploadVideo(uploadInfo.videoId).then(({ data }) => {
            let uploadAuth = data.uploadAuth;
            uploader.resumeUploadWithAuth(uploadAuth);
            console.log(
              "upload expired and resume upload with uploadauth " + uploadAuth
            );
          });
          self.statusText = "文件超时...";
        },
        // 全部文件上传结束
        onUploadEnd: function() {
          console.log("onUploadEnd: uploaded all the files");
          self.statusText = "文件上传完毕";
        }
      });
      return uploader;
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-box {
  margin: 0 auto;
  width: 1080px;

  .title {
    font-size: 20px;
    font-weight: bolder;
  }
}

.over {
  min-height: 900px;
  text-align: center;

  img {
    margin-top: 20px;
  }

  div {
    font-size: 20px;
    font-weight: bold;
    line-height: 3em;
  }

  p {
    line-height: 1.5em;
  }
}

.add-btn {
  color: #37f;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.tips {
  bottom: 12px;
  color: #555;
  font-size: 14px;
  left: 6px;
  position: absolute;
  width: 300px;
}
</style>
